import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const scopeValidation = (scopesRequested, scopesRetrieved) => {
  let isValid = true
  // remove open id
  scopesRequested = scopesRequested.filter((item) => item !== 'openid')

  // validate we have something to compare against
  if (!scopesRetrieved || scopesRetrieved.length <= 0) {
    isValid = false
  }

  // we should have the same scopes
  isValid =
    scopesRequested.length >= scopesRetrieved.length &&
    scopesRequested.every((scope) => scopesRetrieved.includes(scope))

  if (!isValid) toast.error(`Not all requested scopes were returned.`)
}

export { scopeValidation }
