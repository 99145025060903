import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import './Tile.scss'

const Tile = ({ name, counts, onClick }) => {
  /**
   * Statistic item rendered inside of the overview card.
   * @param {object} props
   * @param {string} name - Title of the statistic
   * @param {string} value - Value of the statistic
   */
  function BodyItem({ name, value }) {
    return (
      <div className="info">
        <div className="name">{name}</div>
        <div className="value">{value}</div>
      </div>
    )
  }

  return (
    <div className="tile">
      <div className="header" onClick={onClick}>
        <FontAwesomeIcon icon={faLayerGroup} />
        {name}
      </div>
      <div className="body">
        {counts &&
          Object.keys(counts).map((c) => {
            return <BodyItem name={c} value={counts[c]} key={`tile-${c}`} />
          })}
      </div>
    </div>
  )
}
export default Tile
