import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './UserManagementBaseCard.scss'

const UserManagementBaseCard = (props) => {
  // destructure props
  const { icon, groupName, formRows } = props

  return (
    <div class="user-card">
      <div className="info">
        <div className="icon-background">
          <FontAwesomeIcon class="icon" icon={icon} />
        </div>
        <span class="text">{groupName}</span>
      </div>
      <div className="form-container">{formRows}</div>
    </div>
  )
}

export default UserManagementBaseCard
