import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStoreState } from 'easy-peasy'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import { useEffect, useMemo } from 'react'
import { getReports } from '../../API/Services/Report'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import Table from '../../Components/Table'

const ReportLayout = () => {
  const currentPartner = useStoreState((state) => state.partner.selected)
  return (
    <div className="App-module">
      <h3 style={{ textAlign: 'left' }}>
        {currentPartner?.label} / <Link to="..">Report</Link>
      </h3>
      <Outlet />
    </div>
  )
}

/**
 * Reports view.
 */
const ReportTable = (props) => {
  const reports = useStoreState((state) => state.report.records)
  const getReports = useStoreActions((actions) => actions.report.getRecords)
  const reportsLoading = useStoreState((state) => state.report.recordsLoading)

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        filter: 'fuzzyText',
      },
      {
        Header: 'Status',
        accessor: 'lastRunStatus',
      },
      {
        Header: 'Progress',
        accessor: 'lastRunProgress',
      },
      {
        Header: 'Message',
        accessor: 'lastRunMessage',
      },
      {
        Header: 'Last Start Time',
        accessor: 'lastRunStarted',
        Cell: (props) => props.value?.toLocaleString(),
        disableFilters: true,
      },
      {
        Header: 'Last Completion Time',
        accessor: 'lastRunCompleted',
        Cell: (props) => props.value?.toLocaleString(),
        disableFilters: true,
      },
    ],
    []
  )

  useEffect(() => {
    getReports()
  }, [])

  return (
    <div className="App-submodule">
      <div className="row" style={{ alignItems: 'flex-start' }}>
        <div style={{ flexGrow: 1 }}>
          <h3>Reports</h3>
          <Table singleSelect loading={reportsLoading} columns={tableColumns} data={reports} />
        </div>
      </div>
    </div>
  )
}

/**
 * Accounts routes for administration.
 */
const ReportsPage = () => {
  return (
    <Routes>
      <Route path="/" element={<ReportLayout />}>
        <Route path="" element={<ReportTable />} />
      </Route>
    </Routes>
  )
}

export default ReportsPage
