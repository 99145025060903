import { useStoreActions, useStoreState } from 'easy-peasy'
import DropdownField from './Form/DropdownField'
import { useMemo } from 'react'

const SiteSelector = ({ className }) => {
  const siteOptions = useStoreState((state) => state.site.records)
  const siteSelector = useStoreActions((actions) => actions.site.setSelected)
  const selectedSite = useStoreState((state) => state.site.selected)
  const formattedOptions = useMemo(() => siteOptions?.map((x) => ({ value: x.ID, label: x.Name })) ?? [], [siteOptions])

  return (
    <DropdownField
      className={className}
      onTop={true}
      label="Site"
      name="selectedSite"
      value={selectedSite}
      onChange={siteSelector}
      options={formattedOptions}
    />
  )
}

export default SiteSelector
