import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

function SearchBox(props) {
  return (
    <div className="search">
      <FontAwesomeIcon
        icon={faSearch}
        style={{ paddingRight: '12px', color: '#485465', opacity: '50%', width: '15px' }}
      />
      <input
        type="text"
        placeholder="Search"
        value={props.value ?? ''}
        onChange={props.onUpdate}
        style={{ border: 'none', outline: 'none' }}
      />
    </div>
  )
}
export default SearchBox
