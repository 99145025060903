import { useStoreState } from 'easy-peasy'
import { Routes, Route, Outlet, Link } from 'react-router-dom'

const ChildrenLayout = () => {
  const currentPartner = useStoreState((state) => state.partner.selected)
  return (
    <div className="App-module">
      <h3 style={{ textAlign: 'left' }}>
        {currentPartner?.label} / <Link to="..">Utility Programs</Link>
      </h3>
      <Outlet />
      <h1>Utility Programs</h1>
    </div>
  )
}

/**
 * UtilityPrograms routes.
 */
const UtilityPrograms = () => {
  return (
    <Routes>
      <Route path="/" element={<ChildrenLayout />}></Route>
    </Routes>
  )
}

export default UtilityPrograms
