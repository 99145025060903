import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom'
import AuthenticatedRoutes from './Authenticated'
import LoginPage from './Login'
import LogoutPage from './Logout'
import NotFoundPage from './NotFound'
import LogoutButton from '../Components/LogoutButton'
import PartnerSelector from '../Components/PartnerSelector'
import NavigationTabs from '../Components/NavigationTabs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { tokenService } from '../Utilities/Token'
import AlertProvider from '../Components/AlertProvider'
import { InteractionStatus } from '@azure/msal-browser'
import { useStoreRehydrated, useStoreActions } from 'easy-peasy'

/**
 * Layout component.
 */
const Layout = () => {
  const isRehydrated = useStoreRehydrated()

  return (
    <div className="App">
      <header className="App-header">
        <div>
          <FontAwesomeIcon icon={faWarehouse} style={{ paddingRight: '12px' }} />
          Edo Application Administration
        </div>
        <div className="header-group">
          <PartnerSelector />
          <LogoutButton />
        </div>
      </header>
      <NavigationTabs />
      <div className="App-body">{isRehydrated ? <Outlet /> : 'Loading...'}</div>
      <AlertProvider />
    </div>
  )
}

/**
 * Authenticated routes component.
 */
const RequireAuth = ({ children }) => {
  const [needsAuth, setNeedsAuth] = useState(false)
  const { instance, accounts, inProgress } = useMsal()
  const updateUser = useStoreActions((actions) => actions.auth.updateUser)

  const isAuthenticated = useIsAuthenticated()
  let location = useLocation()

  // Hook for setting access token hook when user is authenticated
  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      // save getAccessTokenSilently for use in apiclient interceptor
      tokenService.setAccessTokenSilently((scopes = []) =>
        instance.acquireTokenSilent({ scopes, account: accounts[0] })
      )

      updateUser(accounts[0])
    }
  }, [isAuthenticated, instance, accounts, inProgress])

  useEffect(() => {
    // redirect user to login when not authenticated or currently authenticating
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      setNeedsAuth(true)
    }
  }, [isAuthenticated, inProgress])

  if (needsAuth) return <Navigate to="/login" state={{ from: location }} replace />

  return children
}

RequireAuth.propTypes = {
  children: PropTypes.node,
}

/**
 * Root component for routing.
 */
const Root = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route
          path="/*"
          element={
            <RequireAuth>
              <AuthenticatedRoutes />
            </RequireAuth>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

export default Root
