import { faServer } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStoreState } from 'easy-peasy'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import ServicePage from '../Users/Service'

const AccountsLayout = () => {
  const currentPartner = useStoreState((state) => state.partner.selected)
  return (
    <div className="App-module">
      <h3 style={{ textAlign: 'left' }}>
        {currentPartner?.label} / <Link to="..">Accounts</Link>
      </h3>
      <Outlet />
    </div>
  )
}

const IndexPage = () => {
  return (
    <div className="App-submodule">
      <div>
        <FontAwesomeIcon icon={faServer} style={{ marginRight: '12px', cursor: 'pointer' }} />
        <Link to="service">Services</Link>
      </div>
    </div>
  )
}

/**
 * Accounts routes for administration.
 */
const Accounts = () => {
  return (
    <Routes>
      <Route path="/" element={<AccountsLayout />}>
        <Route path="" element={<IndexPage />} />
        <Route path="/service" element={<ServicePage />} />
      </Route>
    </Routes>
  )
}

export default Accounts
