import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import './DatePicker.scss'

/**
 * Date input field
 * @param {object} params
 * @param {string} params.label - Label of the date field
 * @param {boolean} params.required - Whether or not this field is required
 * @param {string} params.initial - Initial date value
 * @param {string} params.value - Current date value
 * @param {function} params.onChange - Function to handle date change
 * @param {string} params.className - Additional CSS classes
 */
function DatePicker({ label, required, initial, value, onChange, className, ...restProps }) {
  const inputRef = useRef(null)
  const touched = value !== undefined && initial !== value

  const handleDateChange = (e) => {
    onChange(e.target.value)
  }

  const handleCalendarToggle = () => {
    inputRef.current.showPicker()
  }

  return (
    <div className={'form-field date-picker' + (!value ? ' error' : '')}>
      {label && <div className="title">{label + (required ? '*' : '')}</div>}
      <div className="input-wrapper date-picker__input-container">
        <input
          ref={inputRef}
          type="date"
          className={`date-picker__input ${className || ''} ${touched ? 'touched' : ''} has-suffix`}
          value={value ?? ''}
          onChange={handleDateChange}
          required={required}
          {...restProps}
        />
        <FontAwesomeIcon
          icon={faCalendarAlt}
          className="input-suffix date-picker__icon"
          onClick={handleCalendarToggle}
        />
      </div>
    </div>
  )
}

export default DatePicker
