import { useStoreActions, useStoreState } from 'easy-peasy'
import { useCallback, useState, useRef } from 'react'
import EditModal from '../BaseModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import EditForm from './EditForm'

/**
 * Modal that allows creating a new Service
 */
function CreateModal({ defaultModel, show, onToggle, refreshCertificates }) {
  // -- LOCAL STATE --
  const [pendingForm, setPendingForm] = useState(defaultModel)
  const submitHTML = useRef(null)

  // -- GLOBAL STATE --
  const loading = useStoreState((state) => state.service.createLoading)

  // -- GLOBAL ACTIONS --
  const createService = useStoreActions((actions) => actions.service.create)

  /**
   * Handler for updating the pending object based on key reference.
   * @param {Object} payload - Object values to overwrite existing pending keys
   */
  const handleRawChange = (payload) => {
    setPendingForm({
      ...pendingForm,
      ...payload,
    })
  }

  /**
   * Handler for updating input field changes in the pending object.
   * @param {Event} event - Event on the form field update.
   */
  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setPendingForm({
      ...pendingForm,
      [name]: value,
    })
  }

  /**
   * Toggle the modal state to hidden/visible and reset the pending form to default
   */
  const toggleModal = useCallback(() => {
    setPendingForm(defaultModel)
    onToggle()
  }, [setPendingForm, onToggle, defaultModel])

  /**
   * Handler for submitting the form, creating a new building.
   */
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()

      let payload = {
        displayName: pendingForm.displayName,
        description: pendingForm.description,
        permissions: pendingForm.permissions,
        ...(pendingForm.expiryDays && { expiryDays: pendingForm.expiryDays }),
      }

      // post the form and refresh the building options
      createService(payload).then((certs) => {
        if (certs) {
          const url = window.URL.createObjectURL(new Blob([certs]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'certificates.pem')
          document.body.appendChild(link)
          link.click()
        }
        refreshCertificates()
        toggleModal()
      })
    },
    [createService, refreshCertificates, toggleModal, pendingForm]
  )

  return (
    <EditModal
      title="Register New Service"
      show={show}
      onHide={toggleModal}
      autoFocus={false}
      onSubmit={() => {
        submitHTML.current.click()
      }}
    >
      {loading && <FontAwesomeIcon pulse icon={faSpinner} className="loading" />}
      <form onSubmit={handleSubmit}>
        <EditForm loading={loading} data={pendingForm} onChange={handleInputChange} onRawChange={handleRawChange} />
        <input ref={submitHTML} type="submit" style={{ display: 'none' }} />
      </form>
    </EditModal>
  )
}

export default CreateModal
