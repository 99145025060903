import { torrensClient } from '../TorrensApi'

/**
 * Get Torrens users by partner Id
 * @param {string} partnerId
 * @returns {Promise<{
 *  data: {
 *    id: string,
 *    username: string,
 *    email: string,
 *    title: string | null,
 *    firstName: string,
 *    lastName: string,
 *    phoneNumber; string,
 *    clearStorage: boolean,
 *    role: string | null,
 *    configIds: number[]
 *   }[]
 * }>}
 */
export const getUsers = (partnerId) => {
  return torrensClient.get('/account', {
    headers: {
      ['Edo-PartnerId']: partnerId,
    },
  })
}
