import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

function LogoutButton() {
  const navigate = useNavigate()
  return (
    <div className="logout" onClick={() => navigate('/logout')}>
      Logout
      <FontAwesomeIcon icon={faSignOutAlt} style={{ paddingLeft: '12px' }} />
    </div>
  )
}

export default LogoutButton
