import { apiClient } from '../API'

/**
 * @typedef RegeneratedCertificateResponse
 * @property {string} cert PEM formatted certificate
 */

/**
 * @typedef PostPermission
 * @property {string} resource Resource identifier for permissions
 * @property {string[]} values Permissions for resource
 */

/**
 * @typedef PostCertificateUser
 * @property {string[]} partnerIds Array of partner ids
 * @property {PostPermission[]} permissions Permissions to initialize user with
 * @property {string} certificate.displayName Display name for certificate
 * @property {string} certificate.description Description for certificate
 * @property {string} certificate.name Primary name for procedurally generating certificate username
 * @property {string} certificate.nameSecondary Secondary name for procedurally generating certificate username
 * @property {bool} certificate.internal Flag for whether or not certificate is for internal use
 * @property {number} [certificate.expiryDays=null] Custom validity period for certificate
 */

/**
 * Gets partner's certificates.
 */
export const getPartnerCertificates = (partnerid) => {
  return apiClient.get(`/auth/partner/${partnerid}/certificate`)
}

/**
 * Post new certificate.
 * @param {PostCertificateUser} payload Request payload for certificate user
 * @returns {Promise<import('axios').AxiosResponse<string>>}
 */
export const postUserCertificate = (payload) => {
  return apiClient.post(`/auth/user/external-service`, payload)
}

/**
 * Post a certificate regeneration.
 * @param {string} username Username to regenerate the certificate for
 * @param {number} [expiryDays=null] Days for certificate validity
 * @returns {Promise<import('axios').AxiosResponse<RegeneratedCertificateResponse>>}
 */
export const postUserCertificateRegenerate = (username, expiryDays = null) => {
  let payload = {
    ...(expiryDays && { expiryDays }),
  }

  return apiClient.post(`/auth/user/${username}/certificate/regenerate`, payload)
}
