import './EditBox.scss'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const EditBox = ({ label, children, onSubmit, onCancel, onDelete, disableSubmit }) => {
  return (
    <div className="edit-box">
      <div className="header">{label}</div>
      <form onSubmit={onSubmit}>
        <div className="content">{children}</div>
        {(onDelete || onCancel || onSubmit) && (
          <div className="footer">
            {onDelete && (
              <div className="button no-fill" onClick={onDelete}>
                <FontAwesomeIcon icon={faTrash} style={{ paddingRight: '4px' }} />
                Delete
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {onCancel && (
                <div className="button no-fill" onClick={onCancel}>
                  Cancel
                </div>
              )}
              {onSubmit && (
                <input type="submit" className={'button ' + (disableSubmit ? 'button-disabled' : '')} value="Save" />
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

EditBox.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  disableSubmit: PropTypes.bool,
}

export default EditBox
