import { faCircleCheck, faCircleMinus, faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectColumnFilter from '@components/SelectColumnFilter'
import filterContains from '@utilities/FilterContains'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { formatUnix } from '@utilities/Formatters'
import Table from '@components/Table/Table'
import EditBox from '@components/EditBox/EditBox'

const statusCell = (status) => {
  const statusComponent = (icon, color, status) => {
    return (
      <div>
        <FontAwesomeIcon icon={icon} style={{ paddingRight: '6px', color }} />
        {status}
      </div>
    )
  }

  switch (status) {
    case 'Failed':
      return statusComponent(faCircleXmark, 'red', 'Failed')
    case 'Completed':
      return statusComponent(faCircleCheck, 'green', 'Completed')
    case 'Running':
      return statusComponent(faCircleMinus, 'blue', 'Running')
    default:
      return statusComponent(faCircleQuestion, 'gray', 'Pending')
  }
}

const determineStatus = (finishedOn, failedReason, processedOn) => {
  if (failedReason) {
    return 'Failed'
  }

  if (finishedOn && processedOn) {
    return 'Completed'
  }

  if (processedOn) {
    return 'Running'
  }

  return 'Pending'
}

const JobHistory = () => {
  // --- LOCAL STATE ---
  const [selectedJob, setSelectedJob] = useState([])

  // --- GLOBAL STATE ---
  const jobsLoading = useStoreState((state) => state.openadrqueue.jobHistoryLoading)
  const jobs = useStoreState((state) => state.openadrqueue.jobHistory)
  const getJobLogs = useStoreActions((action) => action.openadrqueue.getJobLogs)
  const setJobLogs = useStoreActions((action) => action.openadrqueue.setJobLogs)
  const logsLoading = useStoreState((state) => state.openadrqueue.jobLogsLoading)
  const logs = useStoreState((state) => state.openadrqueue.jobLogs)

  /**
   * Handler for selected row updates to be passed to table
   * @param {Object[]} selection - Array of selected rows
   */
  const handleSelectionChange = useCallback(
    (selection) => {
      setSelectedJob(selection)
    },
    [setSelectedJob]
  )

  useEffect(() => {
    if (selectedJob.length) {
      getJobLogs(selectedJob[0].id)
    } else {
      setJobLogs([])
    }
  }, [selectedJob])

  // -- TABLE SETTINGS --
  // all valid column options for the job table
  const jobColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'data.displayName',
        filter: 'fuzzyText',
      },
      {
        Header: 'Status',
        accessor: (row) => determineStatus(row.finishedOn, row.failedReason, row.processedOn),
        Cell: ({ value }) => statusCell(value),
        Filter: SelectColumnFilter,
        filter: filterContains,
      },
      {
        Header: 'Process',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'Progress',
        accessor: 'progress',
        disableFilters: true,
        Cell: ({ value }) => value + '%',
      },
      {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true,
      },
      {
        Header: 'Processed On',
        accessor: 'processedOn',
        Cell: ({ value }) => formatUnix(value),
        disableFilters: true,
      },
      {
        Header: 'Finished On',
        accessor: 'finishedOn',
        Cell: ({ value }) => formatUnix(value),
        disableFilters: true,
      },
    ],
    []
  )

  return (
    <>
      <div className="table">
        <Table
          loading={jobsLoading}
          singleSelect
          columns={jobColumns}
          data={jobs}
          initialPageSize={15}
          onSelectionChange={handleSelectionChange}
        />
      </div>
      {selectedJob.length > 0 && (
        <EditBox label={selectedJob[0].data?.displayName + ' - ' + selectedJob[0].id + ' - Job Details'}>
          <h3>Logs</h3>
          <div style={{ textAlign: 'left', margin: '8px', border: '1px solid #D8D8D8' }}>
            {logs.map((l, i) => (
              <div style={{ padding: '2px', borderBottom: '1px solid #D8D8D8' }} key={'log' + i}>
                {l}
              </div>
            ))}
          </div>
          {selectedJob[0].failedReason && (
            <>
              <h3>Failure Reason</h3>
              <div style={{ textAlign: 'left', margin: '8px' }}>{selectedJob[0].failedReason}</div>
            </>
          )}
          {logsLoading && 'Loading...'}
        </EditBox>
      )}
    </>
  )
}

export default JobHistory
