import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

/**
 * @typedef {Object} DetailItem
 * @property {string} [label=''] - Unit ID
 * @property {string} [value=''] - Value
 */
/**
 * Renders a styled detail item
 * @param {DetailItem} params
 */
function DetailItem({ label = '', value = '' }) {
  return (
    <div style={{ display: 'flex', marginBottom: '8px', fontSize: '12px' }}>
      <div
        style={{
          color: '#0A3F54',
          fontWeight: 'bold',
          display: 'flex',
          marginLeft: '20px',
          textAlign: 'left',
          alignItems: 'center',
        }}
      >
        {label}:
      </div>
      <div style={{ marginLeft: '4px', textAlign: 'left', display: 'flex', alignItems: 'center' }}>{value}</div>
    </div>
  )
}

/**
 * Renders a collapsable details section
 * @param {object} params
 * @param {DetailItem[]} params.details - Array of detail items
 */
function AdditionalDetails({ details = [] }) {
  const [visible, setVisible] = useState(false)
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setVisible(!visible)}>
        <FontAwesomeIcon
          icon={visible ? faChevronUp : faChevronRight}
          style={{ marginLeft: '16px', marginRight: '8px' }}
        />
        Additional Details
      </div>
      {visible && (
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: '8px' }}>
          {details.map((item) => (
            <DetailItem {...item} key={item.key} />
          ))}
        </div>
      )}
    </div>
  )
}

export default AdditionalDetails
