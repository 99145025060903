import { useMemo } from 'react'
import { useStoreState } from 'easy-peasy'
import TextField from '@components/Form/TextField'
import BoolField from '@components/Form/BoolField'
import DropdownField from '@components/Form/DropdownField'

function NodeEditForm({ onSelectChange, onToggleChange, hideOadrIdField, ...props }) {
  // -- GLOBAL STATE --
  const nodeTypeOptions = useStoreState((state) => state.openadr.nodeTypes)

  // deconstruct props
  const { initial, node } = props

  // format and memoize point type options for use in defining meta fields
  const nodeTypes_formatted = useMemo(() => {
    return nodeTypeOptions ? nodeTypeOptions.map((x) => ({ value: x.id, label: x.abbreviation })) : []
  }, [nodeTypeOptions])

  return (
    <div className={'container ' + (props.loading ? ' disabled' : '')}>
      <div className="row">
        <TextField
          initial={initial && initial[0]?.name}
          disabled={props.loading ? true : undefined}
          required
          label="Name"
          name="name"
          value={node.name}
          onChange={props.onChange}
        />
        <TextField
          initial={initial && initial[0]?.deviceId}
          disabled={props.loading ? true : undefined}
          required
          label="Device ID"
          name="deviceId"
          value={node.deviceId}
          onChange={props.onChange}
        />
        {!hideOadrIdField && (
          <TextField
            initial={initial && initial[0]?.oadrId}
            disabled={props.loading ? true : undefined}
            required
            label="OpenADR ID"
            name="oadrId"
            value={node.oadrId}
            onChange={props.onChange}
          />
        )}
      </div>
      <div className="row">
        <DropdownField
          initial={initial && nodeTypes_formatted.find((x) => x.abbreviation === initial[0]?.typeAbbreviation)}
          required
          label="Type"
          name="typeId"
          options={nodeTypes_formatted}
          onChange={onSelectChange}
          value={nodeTypes_formatted.find((x) => x.value === props.node.typeId)}
        />
        <BoolField
          label="Foreign"
          name="foreign"
          checked={node.foreign}
          onClick={() => onToggleChange({ value: node.foreign, name: 'foreign' })}
        />
      </div>
    </div>
  )
}

export default NodeEditForm
