import { faCircleNodes, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { Routes, Route } from 'react-router-dom'
import NodeManagement from './NodeManagement'
import JobQueueManagement from './QueueManagement'
import NavigationPath from '../../Components/NavigationPath/NavigationPath'
import OverviewElement from '../../Components/OverviewElement/OverviewElement'
import { useStoreState } from 'easy-peasy'

/**
 * OpenADR routes for administration.
 */
const OpenADR = () => {
  const currentPartner = useStoreState((state) => state.partner.selected)

  const IndexPage = () => {
    return (
      <div className="App-submodule">
        <div>
          <OverviewElement icon={faCircleNodes} link="node" text="Node Management" />
          <OverviewElement icon={faLayerGroup} link="queue" text="Job Queue Management" />
        </div>
      </div>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<NavigationPath partner={currentPartner} text="OpenADR" />}>
        <Route path="" element={<IndexPage />} />
        <Route path="/node/*" element={<NodeManagement />} />
        <Route path="/queue/*" element={<JobQueueManagement />} />
      </Route>
    </Routes>
  )
}

export default OpenADR
