import './OverviewElement.scss'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * Overview element with an icon and display text that links to a given path
 * @param {object} props
 * @param {FontAwesomeIcon} props.icon - Display icon
 * @param {string} props.link - Navigation link for display text
 * @param {string} props.text - Display text for the link
 */
function OverviewElement(props) {
  return (
    <div className="overview-element">
      <FontAwesomeIcon className="overview-element-icon" icon={props.icon} />
      <Link to={props.link}>{props.text}</Link>
    </div>
  )
}

export default OverviewElement
