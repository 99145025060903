import { action } from 'easy-peasy'
const General = {
  confirmNavigation: false,
  confirmationDialog: '',

  openConfirmNavigationModal: action((state) => {
    state.confirmNavigation = true
  }),

  closeConfirmNavigationModal: action((state) => {
    state.confirmNavigation = false
  }),

  setConfirmationDialog: action((state, payload) => {
    state.confirmationDialog = payload
  }),
}

export default General
