import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faFileAlt, faBuilding } from '@fortawesome/free-solid-svg-icons'
import DropdownField from '@components/Form/DropdownField'
import TextField from '@components/Form/TextField'
import DatePicker from '@components/Form/DatePicker'
import TimePicker from '@components/TimePicker'
import Table from '@components/Table/Table'
import { getPrograms } from '../../API/Services/Control'
import { apiErrorHandler } from '../../Utilities/Error'
import { useStoreActions, useStoreState } from 'easy-peasy'
import EditModal from '@components/EditModal'
import Helpers from '@utilities/Helpers'
import './EventSchedulerSettings.scss'

const EventSchedulerSettings = ({ onValidityChange }) => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0])
  const [duration, setDuration] = useState()
  const [startTime, setStartTime] = useState(null)
  const [selectedProgram, setSelectedProgram] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedTimezone, setSelectedTimezone] = useState([])
  const [programsOptions, setProgramsOptions] = useState([])
  const [buildingCapacities, setBuildingCapacities] = useState({})
  const getBuildings = useStoreActions((actions) => actions.control.getBuildings)
  const buildingOptions = useStoreState((state) => state.control.buildingOptions)
  const [showModal, setShowModal] = useState(false)

  // Initial data
  useEffect(() => {
    const loadData = async () => {
      try {
        const { data } = await getPrograms()
        const structuredData = (data?.data ?? []).map((item) => ({ value: item.id, label: item.name }))
        setProgramsOptions(structuredData)
      } catch (e) {
        console.error(e)
        apiErrorHandler(e)
      }
    }
    loadData()
  }, [])

  const handleCapacityChange = useCallback((buildingId, value) => {
    setBuildingCapacities((prev) => ({
      ...prev,
      [buildingId]: value,
    }))
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Building',
        accessor: 'name',
        Cell: ({ value }) => <span style={{ color: '#3D2F90' }}>{value}</span>,
      },
      {
        Header: 'Site',
        accessor: 'siteName',
        Cell: ({ value }) => <span style={{ color: '#3D2F90' }}>{value}</span>,
      },
      {
        Header: '# of Equipment to Participate',
        accessor: 'equipmentCount',
        Cell: ({ value }) => <div style={{ textAlign: 'center', color: '#3D2F90' }}>{value}</div>,
        disableFilters: true,
      },
      {
        Header: 'Capacity (kW)*',
        accessor: 'capacity',
        Cell: ({ row }) => {
          const isSelected = row.getToggleRowSelectedProps().checked
          // Only show the editable component if row was selected
          return isSelected ? (
            <TextField
              type="number"
              controlled={false}
              defaultValue={buildingCapacities[row.original.id]}
              onBlur={(e) => handleCapacityChange(row.original.id, e.target.value)}
            />
          ) : null
        },
        disableFilters: true,
      },
    ],
    [buildingCapacities, handleCapacityChange]
  )

  // Handle date change
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
  }

  // Handle time change
  const handleTimeChange = (selectedOption) => {
    setStartTime(selectedOption)
  }

  // Handle program change
  const handleProgramChange = (selectedOption) => {
    setSelectedProgram(selectedOption)
    getBuildings(selectedOption.value)
    // Reset capacities when program changes
    setBuildingCapacities({})
  }

  // Validate Timezones
  function checkTimezones(data) {
    // If 1, it's valid
    if (data.length <= 1) return true

    // Create a Set of unique tz, excluding null values
    const timezones = new Set(data.map((item) => item.timezone).filter((tz) => tz !== null))
    // If 0 or 1 unique tz, it's valid
    return timezones.size <= 1
  }

  // Handle selection change
  const handleSelectionChange = useCallback((newSelectedRows) => {
    if (newSelectedRows?.length > 0 && newSelectedRows[0].timezone) setSelectedTimezone([newSelectedRows[0].timezone])
    setSelectedRows(newSelectedRows)
  }, [])

  // Check if all required fields are valid
  const isValid = useCallback(() => {
    // validate tz before the rest
    let tzIsValid = checkTimezones(selectedRows)
    if (!tzIsValid) {
      setShowModal(true)
      return false
    }

    // Check if all selected rows have capacity values
    const allCapacitiesPresent = selectedRows.every(
      (row) => buildingCapacities[row.id] && buildingCapacities[row.id] > 0
    )

    const selectedDateTime = new Date(`${selectedDate}T${Helpers.parseTime(startTime)}:00`)
    const now = new Date()
    return (
      selectedDateTime > now &&
      parseInt(duration) >= 15 &&
      selectedDate &&
      startTime &&
      duration &&
      selectedRows.length > 0 &&
      allCapacitiesPresent
    )
  }, [selectedDate, startTime, duration, selectedRows, buildingCapacities])

  // If valid send data
  useEffect(() => {
    const valid = isValid()

    const data = valid
      ? {
          selectedDate,
          startTime,
          duration,
          selectedProgram,
          selectedRows: selectedRows.map((row) => ({
            ...row,
            capacity: buildingCapacities[row.id],
          })),
          selectedTimezone: selectedTimezone?.length > 0 ? selectedTimezone[0] : null,
        }
      : null
    onValidityChange(valid, data)
  }, [selectedDate, startTime, duration, selectedProgram, selectedRows, buildingCapacities, onValidityChange, isValid])

  return (
    <div className="schedule-program-component">
      <table className="main-table">
        <tbody>
          <tr>
            <td className="icon-cell">
              <div className="icon-circle">
                <FontAwesomeIcon width={19} height={19} className="icon" icon={faCalendarAlt} />
              </div>
              <h2 className="section-title step-font">Schedule</h2>
            </td>
            <td className="content-cell">
              <div className="schedule-inputs">
                <div className="input-group">
                  <DatePicker
                    label={'Date'}
                    initial={selectedDate}
                    value={selectedDate}
                    onChange={handleDateChange}
                    required
                  />
                </div>
                <div className="input-group">
                  <TimePicker label={'Start Time'} required onChange={handleTimeChange} value={startTime} />
                </div>
                <div className="input-group">
                  <TextField
                    required
                    type="number"
                    min="15"
                    label="Duration"
                    suffix="minutes"
                    onChange={(e) => setDuration(e.target.value)}
                    value={duration}
                  />
                </div>
                <div className="input-group">
                  <TextField initial={selectedTimezone[0]} label="Time Zone" value={selectedTimezone[0]} disabled />
                </div>
              </div>
              <div className="schedule-info">{"Note: Input start time for the building's local time zone."}</div>
            </td>
          </tr>

          <tr>
            <td className="icon-cell">
              <div className="icon-circle">
                <FontAwesomeIcon width={19} height={19} className="icon" icon={faFileAlt} />
              </div>
              <h2 className="section-title step-font">Select Program</h2>
            </td>
            <td className="content-cell">
              <div className="program-select">
                <DropdownField
                  label={'Program'}
                  options={programsOptions}
                  onChange={handleProgramChange}
                  value={selectedProgram}
                  isSearchable={false}
                />
              </div>
            </td>
          </tr>

          <tr>
            <td className="icon-cell">
              <div className="icon-circle">
                <FontAwesomeIcon width={19} height={19} className="icon" icon={faBuilding} />
              </div>
              <h2 className="section-title step-font">Select Buildings</h2>
            </td>
            <td className="content-cell" style={{ padding: 0 }}>
              <Table
                columns={columns}
                data={buildingOptions}
                initialPageSize={15}
                className={'building-table'}
                onSelectionChange={handleSelectionChange}
                initialHiddenColumns={['equipmentCount']}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <EditModal
        title="Error"
        show={showModal}
        autoFocus={false}
        showOkButton={true}
        onSubmit={() => {
          setShowModal(false)
        }}
      >
        <span style={{ margin: '16px', display: 'flex' }}>
          Time zones associated with selected buildings do not match. Events can only be scheduled for multiple
          buildings in a single time zone.
        </span>
      </EditModal>
    </div>
  )
}

export default EventSchedulerSettings
