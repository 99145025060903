import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

/**
 * Logout component
 */
const Logout = () => {
  const { instance } = useMsal()

  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  // use Azure B2C logout whenever component is loaded
  useEffect(() => {
    if (isAuthenticated) {
      instance.handleRedirectPromise().then(() => instance.logoutRedirect().catch((e) => console.error(e)))
    } else {
      navigate('/', { replace: true })
    }
  }, [instance, isAuthenticated, navigate])

  return <div>Logging Out...</div>
}
export default Logout
