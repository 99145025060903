import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-overlays'
import Button from './Form/Button'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useStoreState } from 'easy-peasy'
import useConfirm from '../Hooks/useConfirm'

function UnsavedChangesModal() {
  const showModal = useStoreState((state) => state.general.confirmNavigation)
  const dialog = useStoreState((state) => state.general.confirmationDialog)
  const { onConfirm, onCancel } = useConfirm()

  return (
    <Modal
      className="modal"
      renderBackdrop={() => <div className="backdrop"></div>}
      onHide={onCancel}
      show={showModal}
      autoFocus={false}
    >
      <div>
        <div className="header">
          Unsaved Changes
          <FontAwesomeIcon onClick={onCancel} icon={faTimes} style={{ float: 'right', cursor: 'pointer' }} />
        </div>
        <div className="content">
          <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
            <p>{dialog}</p>
          </div>
        </div>
        <div className="footer">
          <div className="button no-fill" onClick={onCancel}>
            Cancel
          </div>
          <Button label="Continue" onClick={onConfirm} />
        </div>
      </div>
    </Modal>
  )
}

export default UnsavedChangesModal
