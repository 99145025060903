import { action, actionOn, thunk, thunkOn } from 'easy-peasy'
import { getUserPartners } from '../../API/Services/Partner'

const Partner = {
  selected: null,
  options: null,
  dropdownOptions: null,
  optionsLoading: false,

  setSelected: action((state, payload) => {
    state.selected = payload
  }),

  setOptions: action((state, payload) => {
    state.options = payload
  }),

  openOptionsLoader: action((state) => {
    state.optionsLoading = true
  }),

  closeOptionsLoader: action((state) => {
    state.optionsLoading = false
  }),

  getUserPartners: thunk(async (actions, username) => {
    let partnerResults = []
    actions.openOptionsLoader()

    try {
      partnerResults = await getUserPartners(username)
    } catch (e) {
      console.log(e)
    }

    actions.setOptions(partnerResults.data)

    actions.closeOptionsLoader()
  }),

  onUserUpdate: thunkOn(
    (_, storeActions) => storeActions.auth.setUser,

    async (actions, target) => {
      await actions.getUserPartners(target.payload)
    }
  ),

  // refresh the dropdown options when the partner options are refreshed
  onSetOptions: actionOn(
    (actions) => actions.setOptions,

    (state, target) => {
      state.dropdownOptions = target.payload.map((p) => ({ value: p.id, label: p.name }))
    }
  ),
}

export default Partner
