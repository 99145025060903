import { action, thunk } from 'easy-peasy'
import {
  getPartnerCertificates,
  postUserCertificate,
  postUserCertificateRegenerate,
} from '../../API/Services/Certificate'
import { deleteUser } from '../../API/Services/User'
import { apiErrorHandler } from '../../Utilities/Error'

const Service = {
  selected: null,
  records: null,
  recordsLoading: false,
  createLoading: false,
  deleteLoading: false,

  setSelected: action((state, payload) => {
    state.selected = payload
  }),

  setRecords: action((state, payload) => {
    state.records = payload
  }),

  openRecordsLoader: action((state) => {
    state.recordsLoading = true
  }),

  closeRecordsLoader: action((state) => {
    state.recordsLoading = false
  }),

  openCreateLoader: action((state) => {
    state.createLoading = true
  }),

  closeCreateLoader: action((state) => {
    state.createLoading = false
  }),

  openDeleteLoader: action((state) => {
    state.deleteLoading = true
  }),

  closeDeleteLoader: action((state) => {
    state.deleteLoading = false
  }),

  getRecords: thunk(async (actions, _, { getStoreState }) => {
    let results = []
    actions.openRecordsLoader()

    let partnerId = getStoreState().partner.selected?.value

    if (partnerId) {
      try {
        results = await getPartnerCertificates(partnerId)

        // apply formatting
        results = results.data.map((c) => ({
          ...c,
          lastIssued: new Date(c.lastIssued),
          updated: new Date(c.updated),
          created: new Date(c.created),
          expires: new Date(c.expires),
        }))

        actions.setRecords(results)
      } catch (e) {
        apiErrorHandler(e)
      }
    }

    actions.closeRecordsLoader()
  }),

  create: thunk(async (actions, payload, { getStoreState }) => {
    actions.openCreateLoader()

    let partnerId = getStoreState().partner.selected?.value

    let requestPayload = {
      partnerIds: [partnerId],
      permissions: payload.permissions,
      certificate: {
        displayName: payload.displayName,
        description: payload.description,
        name: null,
        nameSecondary: null,
        internal: false,
        ...(payload.expiryDays && { expiryDays: payload.expiryDays }),
      },
    }

    let certs = null
    try {
      certs = await postUserCertificate(requestPayload)
    } catch (e) {
      apiErrorHandler(e)
    }

    actions.closeCreateLoader()

    return certs?.data
  }),

  regenerate: thunk(async (actions, { username, expiryDays }) => {
    actions.openCreateLoader()

    let certs = null
    try {
      certs = await postUserCertificateRegenerate(username, expiryDays)
    } catch (e) {
      apiErrorHandler(e)
    }

    actions.closeCreateLoader()

    return certs?.data
  }),

  delete: thunk(async (actions, username) => {
    actions.openDeleteLoader()

    let success = true
    try {
      await deleteUser(username)
    } catch (e) {
      apiErrorHandler(e)
      success = false
    }

    actions.closeCreateLoader()

    return success
  }),
}

export default Service
