import BaseModal from './BaseModal/BaseModal'

function EditModal({ onHide, ...restProps }) {
  return (
    <>
      <BaseModal onHide={onHide} {...restProps} />
    </>
  )
}

export default EditModal
