import { useStoreActions } from 'easy-peasy'
import { useCallback, useState, useEffect } from 'react'
import SwitchSelector from '@components/SwitchSelector/SwitchSelector'
import UnsavedChangesModal from '@components/UnsavedChangesModal'
import EditNode from './Node/EditNode.js'
import useConfirm from '@hooks/useConfirm'

import { Display, options } from '@utilities/Constants'

const NodeManagement = () => {
  const [display, setDisplay] = useState(options[0].id)
  const [dirtyForm, setDirtyForm] = useState(false)
  const [selection, setSelection] = useState([])

  const updateConfirmationDialog = useStoreActions((actions) => actions.general.setConfirmationDialog)

  const getNodeTypes = useStoreActions((actions) => actions.openadr.getNodeTypes)

  const { confirm } = useConfirm()

  /**
   * Handler for switching the current display state (VTN, VEN)
   * @param {number} id - ID of the display state based on the display options
   */
  const handleDisplaySwitch = (id) => {
    validateNavigation().then((result) => {
      if (result) {
        setDisplay(id)
      }
    })
  }

  /**
   * Handler for preventing lost updates if the user attempts to navigate away from a form with unsaved changes.
   * @param {string} text - Text raised to the user
   */
  const validateNavigation = (
    text = 'You have unsaved changes. Do you want to continue and lose your current updates?'
  ) => {
    updateConfirmationDialog(text)
    if (dirtyForm) {
      return confirm()
    } else {
      return Promise.resolve(true)
    }
  }

  const onSetSelection = useCallback(
    (selection) => {
      setSelection(selection)
    },
    [setSelection]
  )

  const SwitchComp = <SwitchSelector options={options} value={display} onSelect={handleDisplaySwitch} />

  useEffect(() => {
    getNodeTypes()
  }, [])

  return (
    <div className="App-submodule">
      {display === Display.VTN && (
        <EditNode
          setDirtyForm={setDirtyForm}
          dirtyForm={dirtyForm}
          SwitchSelector={SwitchComp}
          onSetSelection={onSetSelection}
          selection={selection}
          display={display}
        />
      )}
      {display === Display.VEN && (
        <EditNode
          setDirtyForm={setDirtyForm}
          dirtyForm={dirtyForm}
          SwitchSelector={SwitchComp}
          onSetSelection={onSetSelection}
          selection={selection}
          display={display}
        />
      )}
      <UnsavedChangesModal />
    </div>
  )
}

export default NodeManagement
