/**
 * Basic filter function for checking whether a list of selected options appears in the row value
 * @param {Object[]} rows - Pre-filtered rows from the table
 * @param {string} id - Current column accessor
 * @param {Object[]} filterValue - Value to filter the rows by. Result of a multi-select dropdown selection.
 */
export default function filterContains(rows, id, filterValue) {
  return rows.filter((row) => {
    // get a flat list of the our current selection values
    const values = filterValue.map((x) => x.value)
    // this rows value
    const rowValue = row.values[id]
    // binary check to see if this rows value is included in our valid values
    return values.includes(rowValue)
  })
}
