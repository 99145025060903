import { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { useStoreState } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import EditBox from '@components/EditBox'
import AdditionalDetails from '@components/AdditionalDetails'
import RegisterVENtoVTNModal from '@components/RegisterVENtoVTNModal'
import NodeEditForm from '@components/Node/NodeEditForm'

const PendingNode = ({ pendingForm, selection, ...props }) => {
  // -- LOCAL STATE --
  const [editorLabel, setEditorLabel] = useState('')
  const submitHTML = useRef(null)
  const [formLoading, setFormLoading] = useState(true)

  // -- GLOBAL STATE --
  const nodesLoading = useStoreState((state) => state.openadr.nodesLoading)
  const nodeTypesLoading = useStoreState((state) => state.openadr.nodeTypesLoading)

  const handleSelectionUpdate = useCallback(() => {
    if (selection.length === 1) {
      setEditorLabel(selection[0].name)
      props.setPendingForm(selection[0])
    } else {
      setEditorLabel('')
      props.setPendingForm(null)
    }
  }, [selection, setEditorLabel])

  // -- LIFECYLCE HOOKS --
  // selection changes
  useEffect(() => {
    handleSelectionUpdate()
  }, [handleSelectionUpdate])

  /**
   * Handler for updating input field changes in the pending object.
   * @param {Event} event - Event on the form field update.
   */
  const handleInputChange = (event) => {
    const target = event.target
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'number' && target.value !== ''
        ? Number(target.value)
        : target.value
    const name = target.name

    props.setPendingForm({
      ...pendingForm,
      [name]: value,
    })
  }

  /**
   * Helper for getting the details for the currently selected object.
   * @returns {Object[]} Array of detail items
   */
  const getDetails = useMemo(() => {
    let details = []

    details.push({ label: 'ID', value: pendingForm?.id, key: 1 })

    return details
  }, [pendingForm?.id])

  /**
   * Handler for updating the pending object based on key reference.
   * @param {Object} payload - Object values to overwrite existing pending keys
   */
  const handleRawChange = useCallback(
    (payload) =>
      props.setPendingForm((prevForm) => ({
        ...prevForm,
        ...payload,
      })),
    [props.setPendingForm]
  )

  /**
   * Handler for dropdown select updates on the pending object.
   * @param {Object} option - Selected option from the dropdown field
   * @param {string} name - Name of the field getting updated by the dropdown
   */
  const handleSelectChange = (option, name) => {
    const value = option?.value ?? null

    props.setPendingForm({
      ...pendingForm,
      [name]: value,
    })
  }

  /**
   * Handler for updating a boolean value
   * @param {Object} payload - Object values to overwrite existing pending keys
   */
  const handleOverrideToggle = (payload) => {
    props.setPendingForm({
      ...pendingForm,
      [payload.name]: !payload.value,
    })
  }

  // any form action
  useEffect(() => {
    let loading = (nodesLoading, nodeTypesLoading)

    setFormLoading(loading)
  }, [setFormLoading, nodesLoading, nodeTypesLoading])

  if (!pendingForm) return null

  return (
    <>
      <div className="edit">
        <EditBox
          label={editorLabel}
          disableSubmit={true}
          onSubmit={(e) => {
            e.preventDefault()
            submitHTML.current.click()
          }}
          onCancel={handleSelectionUpdate}
        >
          <form>
            {formLoading && <FontAwesomeIcon pulse icon={faSpinner} className="loading" />}
            <NodeEditForm
              loading={formLoading}
              initial={selection}
              node={pendingForm}
              onChange={handleInputChange}
              onSelectChange={handleSelectChange}
              onRawChange={handleRawChange}
              onToggleChange={handleOverrideToggle}
            />
            <AdditionalDetails details={getDetails} />
            {/*RegisterVENtoVTNModal component only works if foreign flag is false*/}
            {!pendingForm.foreign && <RegisterVENtoVTNModal node={pendingForm} />}
            <input ref={submitHTML} type="submit" style={{ display: 'none' }} />
          </form>
        </EditBox>
      </div>
    </>
  )
}

export default PendingNode
