import { useMemo } from 'react'
import DropdownField from './Form/DropdownField'
/**
 * Multi-select dropdown column filter for the table
 * @param {Object} params
 * @param {Object} params.column - react-table column object
 * @param {Object[]} [params.manualOptions] - Manual options to use for filter
 */
function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id }, manualOptions }) {
  // Calculate the options for filtering
  const options = useMemo(() => {
    // if manual options are defined use those instead of the prefiltered rows
    // this is needed when the table is server side paginated
    if (manualOptions) {
      // alpha sort the values and construct a value/label array
      var options = manualOptions
        .sort((a, b) => String(a.name).localeCompare(String(b.name)))
        .map((x) => ({
          value: x.id,
          label: String(x.name ?? '(empty)'),
        }))
      // if the first two values contains not-empty/empty, then change the order
      const shouldSwap = options.filter((item) => item.value === -1 || item.value === 0)
      if (shouldSwap.length === 2) {
        ;[options[0], options[1]] = [options[1], options[0]]
      }
      return [...options]
    }
    // otherwise use the prefiltered rows to determine all the current options in the table
    else {
      // set to hold the output results since we want them to be unique
      const uniqueOptions = new Set()

      // add each item from the pre-filtered row to the set
      preFilteredRows.forEach((row) => {
        uniqueOptions.add(row.values[id])
      })

      // alpha sort the values and construct a value/label array
      const options = [...uniqueOptions.values()]
        .sort((a, b) => a?.localeCompare(b))
        .map((x) => ({
          value: x,
          label: x ?? '(empty)',
        }))
      return [...options]
    }
  }, [id, preFilteredRows, manualOptions])

  // Render a multi-select box
  return (
    <DropdownField
      className="header"
      dropdownPrefix="header"
      placeholder="Filter"
      isMulti
      options={options}
      onChange={(option) => {
        var notEmptyIndex = -1
        var notEmpty = option.filter((item, index) => {
          return item.value === 0 ? ((notEmptyIndex = index), true) : false
        })

        if (option.length > 1 && notEmpty.length > 0) {
          if (notEmptyIndex === 0) option.shift()
          else option = notEmpty
        }

        return setFilter(option.length > 0 ? option : undefined)
      }}
      value={filterValue || ''}
    />
  )
}

export default SelectColumnFilter
