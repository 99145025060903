export const BackgroundAreaFill = {
  id: 'backgroundAreaFill',
  beforeDraw(chart, _, options) {
    const {
      ctx,
      chartArea: { top, height },
      scales: { x },
    } = chart

    const fillStartX = x.getPixelForValue(options.windowStart)
    const fillEndX = x.getPixelForValue(options.windowEnd)

    ctx.save()
    ctx.fillStyle = 'lightGray'
    ctx.fillRect(fillStartX, top, fillEndX - fillStartX, height)
    ctx.restore()
  },
}
