import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons'
import './Stepper.scss'
import Button from '@components/Form/Button'

const Stepper = ({
  steps,
  currentStep,
  isStepValid,
  onStepChange,
  title,
  handleCancel,
  onSave,
  isCompleted,
  loading = false,
}) => {
  // handle next step
  const handleNext = () => {
    if (isStepValid) {
      if (currentStep < steps.length - 1) {
        onStepChange(currentStep + 1)
      } else if (isLastStep) {
        onSave()
      }
    }
  }

  // handle previous step
  const handleBack = () => {
    if (currentStep > 0) {
      onStepChange(currentStep - 1)
    }
  }

  // Check if we're on the first or last step
  const isFirstStep = currentStep === 0
  const isLastStep = currentStep === steps.length - 1

  return (
    <div className="stepper-wrapper">
      <div className="stepper">
        <div className="step-title">{title}</div>
        <div className="step-indicators">
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <div className="step-indicator-label">
                <div
                  className={`step-indicator step-font ${
                    isCompleted || index < currentStep ? 'completed' : currentStep >= index ? 'active' : ''
                  }`}
                >
                  {isCompleted || index < currentStep ? (
                    <FontAwesomeIcon width={15} height={15} icon={faCheck} />
                  ) : (
                    index + 1
                  )}
                </div>
                <div className={`step-label ${index === currentStep && !isCompleted ? 'active' : ''}`}>{step}</div>
              </div>
              {index < steps.length - 1 && (
                <div className={`step-separator ${isCompleted || currentStep > index ? 'active' : ''}`}></div>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="stepper-buttons">
          {isCompleted ? (
            <Button label="CLOSE" onClick={handleCancel} customClassNames="completed-button" />
          ) : (
            <>
              <Button label="CANCEL" onClick={handleCancel} customClassNames="cancel-button" />

              {!isFirstStep && (
                <Button
                  label={
                    <>
                      <FontAwesomeIcon style={{ marginRight: '8px' }} icon={faChevronLeft} /> BACK
                    </>
                  }
                  onClick={handleBack}
                  disabled={currentStep === 0}
                  customClassNames="back-button"
                />
              )}
              <Button
                label={
                  isLastStep ? (
                    'SAVE'
                  ) : (
                    <>
                      NEXT <FontAwesomeIcon style={{ marginLeft: '8px' }} icon={faChevronRight} />
                    </>
                  )
                }
                onClick={handleNext}
                disabled={!isStepValid || loading}
                customClassNames="next-button"
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Stepper
