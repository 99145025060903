import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'

/**
 * Holds a toast container that renders any errors logged to the error store.
 */
function AlertProvider() {
  return <ToastContainer />
}

export default AlertProvider
