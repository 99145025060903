/**
 * Text input field
 * @param {object} params
 * @param {string} params.label - Label of the text field
 * @param {boolean} params.required - Whether or not this field is required
 * @param {string|ReactNode} params.suffix - Suffix to be displayed inside the text field
 * @param {boolean} params.disabled - Whether the field is disabled
 */
function TextField({
  label,
  required,
  initial,
  value,
  className,
  optionalLabel,
  suffix,
  disabled,
  defaultValue,
  controlled = true,
  ...restProps
}) {
  // We only verify touched when it is controlled
  const touched = controlled && value !== undefined && initial !== value
  // We only check the error when it is controlled
  const hasError = controlled && !value

  return (
    <div className={'form-field' + (hasError ? ' error' : '') + (disabled ? ' disabled' : '')}>
      {label && (
        <div className="title">
          <span>{label + (required ? '*' : '')}</span>
          {optionalLabel && <span className="optional">Optional</span>}
        </div>
      )}
      <div className="input-wrapper">
        <input
          className={`
            ${className || ''}
            ${touched ? 'touched' : ''}
            ${!touched && initial ? 'initial' : ''}
            ${suffix ? 'has-suffix' : ''}
            ${disabled ? 'disabled' : ''}
          `}
          value={controlled ? value ?? '' : undefined}
          defaultValue={!controlled ? defaultValue : undefined}
          {...restProps}
          required={required}
          disabled={disabled}
        />
        {suffix && <span className="input-suffix">{suffix}</span>}
      </div>
    </div>
  )
}

export default TextField
