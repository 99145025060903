import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import './PageHeader.scss'
import '@src/App.scss'

const PageHeader = (props) => {
  const navigate = useNavigate()

  // destructure props
  const { pageName, userActions } = props

  return (
    <div className="wrapper-header">
      <div className="header-tabs">
        <div className="back-button">
          <FontAwesomeIcon
            class="arrow icon"
            onClick={() => {
              navigate(-1)
            }}
            icon={faChevronLeft}
            style={{ float: 'left', cursor: 'pointer' }}
          />
          {pageName && <span className="text">{pageName}</span>}
        </div>
        <div className="actions">
          {userActions.map((a, index) => (
            <React.Fragment key={index}>{a}</React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PageHeader
