/**
 * Return structured response when InputField component changed
 * @param {Event} event
 * @returns {{ name: string, value: string}}
 */
const getNameValueFromInput = (event) => {
  const target = event.target

  const value =
    target.type === 'checkbox'
      ? target.checked
      : target.type === 'number' && target.value !== ''
      ? Number(target.value)
      : target.value
  const name = target.name

  return { name, value }
}

/**
 * Parses and converts time from 12-hour format to 24-hour format
 * @param {string} startTime - Time in 12-hour format (e.g., "3:30 PM")
 * @returns {string|undefined}
 */
const parseTime = (startTime) => {
  if (!startTime) return

  // Split the time string into time and period (AM/PM)
  const [time, period] = startTime.split(' ')
  let [hours, minutes] = time.split(':')
  hours = parseInt(hours)

  // Adjust hours for PM times (except 12 PM)
  if (period.toLowerCase() === 'pm' && hours !== 12) hours += 12

  // Adjust midnight (12 AM) to 00 hours
  if (period.toLowerCase() === 'am' && hours === 12) hours = 0

  // Return the time in 24-hour format, ensuring hours are two digits
  return `${hours.toString().padStart(2, '0')}:${minutes}`
}

export default { getNameValueFromInput, parseTime }
