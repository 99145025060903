import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { StoreProvider } from 'easy-peasy'
import Store from './Store/Store'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import AppConfig from './config'

const root = ReactDOM.createRoot(document.getElementById('root'))

const msalInstance = new PublicClientApplication(AppConfig.msal)
msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  msalInstance.enableAccountStorageEvents()

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)
    }
  })

  Sentry.init({
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ...AppConfig.sentry,
  })

  root.render(
    <React.StrictMode>
      <StoreProvider store={Store}>
        <App instance={msalInstance} />
      </StoreProvider>
    </React.StrictMode>
  )
})
