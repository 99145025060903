import { useCallback, useState, useRef, useMemo, useEffect } from 'react'
import { useStoreActions } from 'easy-peasy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import EditBox from '../../Components/EditBox'
import AdditionalDetails from '../../Components/AdditionalDetails'
import EditForm from './EditForm'

const Pending = ({ pendingForm, selectedRows, ...props }) => {
  const [formLoading, setFormLoading] = useState(true)

  const [editorLabel, setEditorLabel] = useState('')
  const submitHTML = useRef(null)

  const getCertificates = useStoreActions((actions) => actions.service.getRecords)
  const remove = useStoreActions((actions) => actions.service.delete)

  const creating = false
  const updating = false

  const handleDelete = () => {
    // delete the single certificate
    remove(pendingForm.username).then((result) => result && getCertificates())
  }

  const handleSelectedRowUpdate = useCallback(() => {
    if (selectedRows.length === 1) {
      setEditorLabel(selectedRows[0].displayName)
      props.setPendingForm(selectedRows[0])
    } else if (selectedRows.length === 0) {
      setEditorLabel('')
      props.setPendingForm(null)
    } else {
      setEditorLabel('Selected: ' + selectedRows.length)
      let multiEdit = {}
      props.setPendingForm(multiEdit)
    }
  }, [selectedRows, setEditorLabel, props.setPendingForm])

  // -- LIFECYLCE HOOKS --
  // any selected row changes
  useEffect(() => {
    handleSelectedRowUpdate()
  }, [handleSelectedRowUpdate])

  /**
   * Handler for updating input field changes in the pending object.
   * @param {Event} event - Event on the form field update.
   */
  const handleInputChange = (event) => {
    const target = event.target
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'number' && target.value !== ''
        ? Number(target.value)
        : target.value
    const name = target.name

    props.setPendingForm({
      ...pendingForm,
      [name]: value,
    })
  }

  /**
   * Helper for getting the details for the currently selected object.
   * @returns {Object[]} Array of detail items
   */
  const getDetails = useMemo(() => {
    if (!pendingForm) return

    let details = []

    details.push({ label: 'User', value: pendingForm.username })
    details.push({ label: 'Created', value: pendingForm.created.toLocaleString() })
    details.push({ label: 'Updated', value: pendingForm.updated.toLocaleString() })
    details.push({ label: 'Last Issued', value: pendingForm.lastIssued.toLocaleString() })
    details.push({ label: 'Expires', value: pendingForm.expires.toLocaleString() })

    return details
  }, [pendingForm])

  /**
   * Handler for updating the pending object based on key reference.
   * @param {Object} payload - Object values to overwrite existing pending keys
   */
  const handleRawChange = useCallback(
    (payload) =>
      props.setPendingForm((prevForm) => ({
        ...prevForm,
        ...payload,
      })),
    [props.setPendingForm]
  )

  // any form action
  useEffect(() => {
    let loading = creating || updating

    setFormLoading(loading)
  }, [setFormLoading, creating, updating])

  if (!pendingForm) return null

  return (
    <>
      <div className="edit">
        <EditBox label={editorLabel} onCancel={handleSelectedRowUpdate} onDelete={handleDelete} disableSubmit>
          <form>
            {formLoading && <FontAwesomeIcon pulse icon={faSpinner} className="loading" />}
            <EditForm
              loading={formLoading}
              initial={selectedRows}
              data={pendingForm}
              onChange={handleInputChange}
              onRawChange={handleRawChange}
            />
            {selectedRows.length === 1 && <AdditionalDetails details={getDetails} />}
            <input ref={submitHTML} type="submit" style={{ display: 'none' }} />
          </form>
        </EditBox>
      </div>
    </>
  )
}

export default Pending
