import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './Loader.scss'
function Loader({ isRelative, text = 'Loading', backgroundColor = '#f4f4f4' }) {
  let loaderClass = 'loader-container'

  // if isRelative exists, if not we don't add any class there
  if (typeof isRelative == 'boolean')
    if (isRelative) loaderClass += ' relative'
    else loaderClass += ' fullscreen'

  const style = backgroundColor ? { backgroundColor } : {}

  return (
    <div className={loaderClass} style={style}>
      <div className="loader-content">
        <FontAwesomeIcon pulse className="spinner" icon={faSpinner} />
        <span className="loader-text">{text}</span>
      </div>
    </div>
  )
}

export default Loader
