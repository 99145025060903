import { Modal } from 'react-overlays'
import Button from './Form/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Loader from '@components/Loader'
/**
 * Callback for when the modal goes into a hidden state.
 * @callback hideCallback
 */

/**
 * Callback to handle when the pending form displayed in the modal is submitted.
 * @callback submitCallback
 */

/**
 * Base modal layout that contains a header, body, and footer with a save button.
 * @param {object} params
 * @param {string} params.title - Title of the modal used for the header
 * @param {JSX.Element} params.children - Children to render inside the content section
 * @param {hideCallback} params.onHide - Callback that fires when modal is hidden
 * @param {submitCallback} params.onSubmit - Callback that fires when save button is clicked
 * @param {boolean} params.disableSubmit - Flag to indicate submit was disabled
 * @param {boolean} params.loading - Flag to indicate modal is in a loading state
 */
function BaseModal({ title, children, onHide, onSubmit, disableSubmit = false, loading = false, ...restProps }) {
  return (
    <Modal className="modal" renderBackdrop={() => <div className="backdrop"></div>} onHide={onHide} {...restProps}>
      <div>
        {loading && <Loader isRelative={true} />}
        <div className="header">
          {title}
          <FontAwesomeIcon onClick={onHide} icon={faTimes} style={{ float: 'right', cursor: 'pointer' }} />
        </div>
        <div className="content">{children}</div>
        <div className="footer">
          <div className="button no-fill" onClick={onHide}>
            Cancel
          </div>
          <Button label="Save" onClick={onSubmit} disabled={disableSubmit} />
        </div>
      </div>
    </Modal>
  )
}

export default BaseModal
