import { useEffect, useState } from 'react'
import TextField from '../Form/TextField'
import BoolField from '../Form/BoolField'
import ExpiryDropdown from '../ExpiryDropdown'
import RegenerateModal from '../RegenerateModal'

const defaultData = {
  displayName: '',
  description: '',
  permissions: [],
}

const permissionOptions = [
  {
    name: 'Create',
    value: 'public.create',
  },
  {
    name: 'Read',
    value: 'public.read',
  },
  {
    name: 'Update',
    value: 'public.update',
  },
]

function EditForm(props) {
  const [pending, setPending] = useState(defaultData)

  useEffect(() => {
    if (props.data && props.data?.id !== pending.id) {
      setPending(props.data)
    }
  }, [props.data, pending])

  const handlePermssionToggle = (value, resource) => {
    let permissions = [...props.data.permissions]

    let permissionSet = permissions.find((resourcePerm) => resourcePerm.resource === resource)
    let isIncluded = permissionSet.values.includes(value)

    if (isIncluded) {
      permissionSet.values = permissionSet.values.filter((x) => x !== value)
    } else {
      permissionSet.values.push(value)
    }

    props.onRawChange({ permissions })
  }

  const { initial } = props

  return (
    <div className="container">
      <div className="row">
        <TextField
          initial={initial && initial[0]?.displayName}
          disabled={props.loading ? true : undefined}
          required
          label="Name"
          name="displayName"
          value={props.data.displayName}
          onChange={props.onChange}
        />
        <TextField
          initial={initial && initial[0]?.description}
          disabled={props.loading ? true : undefined}
          required
          label="Description"
          name="description"
          value={props.data.description}
          onChange={props.onChange}
        />
        {props.data.creator !== undefined && (
          <TextField
            initial={initial && initial[0]?.creator}
            disabled
            required
            label="Creator"
            name="creator"
            value={props.data.creator}
            onChange={props.onChange}
          />
        )}
        {props.data.isInternal !== undefined && (
          <BoolField
            disabled
            label="Internal"
            name="internal"
            checked={props.data.isInternal}
            onClick={props.onRawChange}
          />
        )}
        {!props.data.username && (
          <ExpiryDropdown
            value={props.data.expiryDays}
            onChange={(option) => props.onRawChange({ expiryDays: option.value })}
          />
        )}
        <div className="row">
          <div className="row">Permissions</div>
          {props.data.permissions !== undefined &&
            permissionOptions.map((p, i) => {
              let permissionSet = props.data.permissions.find((resourcePerm) => resourcePerm.resource === 'service')

              return (
                <BoolField
                  disabled={props.loading ? true : undefined}
                  label={p.name}
                  name={p.value}
                  key={`permission-${i}`}
                  checked={permissionSet?.values.includes(p.value)}
                  onClick={() => handlePermssionToggle(p.value, 'service')}
                />
              )
            })}
        </div>
        {props.data.username && (
          <div className="row">
            <RegenerateModal username={props.data.username} />
          </div>
        )}
      </div>
    </div>
  )
}

export default EditForm
