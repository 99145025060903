import React, { useState, useEffect } from 'react'
import './TabSwitchSelector.scss'

const TabSwitchSelector = ({ tabs, onSelect }) => {
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    onSelect(tabs[activeTab])
  }, [])

  const handleTabClick = (index) => {
    setActiveTab(index)
    onSelect(tabs[index])
  }

  return (
    <div className="tab-switch-selector">
      {tabs.map((tab, index) => (
        <button
          key={index}
          type="button"
          className={`tab-switch ${activeTab === index ? 'active' : ''}`}
          onClick={() => handleTabClick(index)}
        >
          {tab}
        </button>
      ))}
    </div>
  )
}

export default TabSwitchSelector
