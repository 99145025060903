import { apiClient } from '../API'

/**
 * @typedef {object} UpdateUser
 * @property {string} firstName User's first name
 * @property {string} lastName User's last name
 * @property {string} phone User's phone number, with country and area code (e.g. +11234567890)
 */

/**
 * @typedef {object} User
 * @property {string} username Username - typically email address
 * @property {string} firstName User's first name
 * @property {string} lastName User's last name
 * @property {string} phone User's phone number, with country and area code (e.g. +11234567890)
 * @property {string[]} partnerIds String partner identifiers
 * @property {Permission[]} permissions User's permissions
 */

/**
 * @typedef {object} Permission
 * @property {string} resource Resource identifier
 * @property {string[]} values String scope identifiers
 */

/**
 * Get auth users using serverside pagination filtering
 * @param {string} partnerId
 * @param {number} page
 * @param {number} resultsPerPage
 * @param {unknown} filter
 * @param {string=} sortBy
 * @param {string=} sortDirection
 * @returns {Promise<{
 *  data: {
 *    username: string,
 *    firstName: string,
 *    lastName: string,
 *    phone: string,
 *  }[]
 * }>}
 */
export const getUsers = (partnerId, page, resultsPerPage, filter = {}, sortBy, sortDirection) => {
  const params = {
    partner: [partnerId?.toLowerCase()],
    page: page,
    count: resultsPerPage,
    ...filter,
  }

  if (sortBy) {
    params.sortBy = sortBy
  }

  if (sortDirection) {
    params.sortDirection = sortDirection
  }

  return apiClient.get(`/auth/user`, { params })
}

/**
 * Delete a user.
 * @param {string} username
 */
export const deleteUser = (username) => {
  return apiClient.delete(`/auth/user/${username}`)
}

/**
 * Create a user.
 * @param {User} payload
 */
export const postUser = (payload) => {
  return apiClient.post(`/auth/user/`, payload)
}

/**
 * Update a user.
 * @param {string} username
 * @param {UpdateUser} payload
 */
export const patchUser = (username, payload) => {
  return apiClient.patch(`/auth/user/${username}`, payload)
}
