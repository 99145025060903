import { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import CreateNodeModal from './CreateNodeModal'

const defaultNodeData = {
  name: '',
  deviceId: '',
  typeId: null,
}

function CreateNodeButton({ refreshNodes }) {
  const [showEditModal, setShowEditModal] = useState(false)

  const toggleModal = useCallback(() => {
    setShowEditModal(!showEditModal)
  }, [setShowEditModal, showEditModal])

  return (
    <>
      <div onClick={toggleModal} className="create">
        <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '4px' }} />
        Create New
      </div>
      <CreateNodeModal
        refreshNodes={refreshNodes}
        show={showEditModal}
        onToggle={toggleModal}
        defaultModel={defaultNodeData}
      />
    </>
  )
}

export default CreateNodeButton
