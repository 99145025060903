import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import * as Sentry from '@sentry/react'
import { InteractionStatus } from '@azure/msal-browser'

/**
 * Login component handling redirect to Azure B2C sign on
 */
const Login = () => {
  const { inProgress, instance, accounts } = useMsal()

  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      console.log(inProgress)
      instance.loginRedirect().catch((e) => console.error(e))
    }
  }, [isAuthenticated, instance, inProgress])

  useEffect(() => {
    if (isAuthenticated) {
      let user = accounts[0]
      Sentry.setUser({ email: user.email })
      navigate('/', { replace: true })
    }
  }, [isAuthenticated, accounts, navigate])

  return (
    <div>
      {(inProgress !== InteractionStatus.None || isAuthenticated) && (
        <>
          <div className="loader-text">{inProgress !== InteractionStatus.None ? 'Loading...' : 'Logging in...'}</div>
        </>
      )}
    </div>
  )
}
export default Login
