import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useStoreState } from 'easy-peasy'
import DropdownField from './Form/DropdownField'
import './NavigationTabs.scss'

const NavigationTabs = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [activeTab, setActiveTab] = useState('')

  const currentPartner = useStoreState((state) => state.partner.selected)
  const isEdoPartner = currentPartner?.value === 'edo'

  const tabs = [
    { text: 'Events', link: '/event', visible: true },
    { text: 'Users', link: '/users', visible: true },
    { text: 'Utility Programs', link: '/utility-programs', visible: isEdoPartner },
    { text: 'Reports', link: '/report', visible: true },
    { hr: true, visible: isEdoPartner },
  ]

  const openADROptions = [
    { value: 'node', label: 'Node Management' },
    { value: 'queue', label: 'Job Queue Management' },
  ]

  // Handling style when openAdr is being selected
  useEffect(() => {
    const currentPath = location.pathname
    // avoiding loop on login and logout
    if (location.hash.includes('state') || currentPath == '/login' || currentPath == '/logout') return

    // redirecting to first tab if on home page
    if (currentPath === '/') {
      const firstVisibleTab = tabs.find((tab) => tab.visible && !tab.hr)
      if (firstVisibleTab) {
        navigate(firstVisibleTab.link)
        setActiveTab(firstVisibleTab.text)
      }
    } else {
      const activeTabItem = tabs.find((tab) => tab.link === currentPath)
      if (activeTabItem) {
        setActiveTab(activeTabItem.text)
      } else if (currentPath.startsWith('/openadr')) {
        setActiveTab('Open ADR')
      }
    }
  }, [location, navigate, tabs])

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  const handleOpenADRChange = (selectedOption) => {
    setActiveTab('Open ADR')
    switch (selectedOption.value) {
      case 'node':
        navigate('/openadr/node')
        break
      case 'queue':
        navigate('/openadr/queue')
        break
    }
  }

  return (
    <div className="wrapper-navigation">
      <div className="navigation-tabs">
        <div className="tabs-container">
          {tabs
            .filter((tab) => tab.visible)
            .map((tab, index) => (
              <React.Fragment key={index}>
                {tab.hr ? (
                  <hr className="tab-separator" />
                ) : (
                  <Link
                    to={tab.link}
                    className={`tab ${activeTab === tab.text ? 'active' : ''}`}
                    onClick={() => handleTabClick(tab.text)}
                  >
                    {tab.text}
                  </Link>
                )}
              </React.Fragment>
            ))}
          {isEdoPartner && (
            <div className={`open-adr-dropdown ${activeTab === 'Open ADR' ? 'active' : ''}`}>
              <DropdownField
                name="openADR"
                options={openADROptions}
                onChange={handleOpenADRChange}
                placeholder="Open ADR"
                className={`open-adr-selector`}
                isSearchable={false}
                value={
                  activeTab === 'Open ADR'
                    ? openADROptions.find((option) => location.pathname.includes(option.value))
                    : null
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NavigationTabs
