import { action, thunk, thunkOn } from 'easy-peasy'
import { getBuildings } from '../../API/Services/Point'
import { apiErrorHandler } from '../../Utilities/Error'

const Building = {
  selected: null,
  buildingsLoading: false,
  buildingOptions: [],

  setSelected: action((state, payload) => {
    state.selected = payload
  }),

  setBuildings: action((state, payload) => {
    state.buildingOptions = payload
  }),

  openBuildingsLoader: action((state) => {
    state.buildingsLoading = true
  }),

  closeBuildingsLoader: action((state) => {
    state.buildingsLoading = false
  }),

  getBuildings: thunk(async (actions, _, { getStoreState }) => {
    let results = []
    actions.openBuildingsLoader()

    let siteId = getStoreState().site.selected?.value

    if (siteId) {
      try {
        actions.setSelected(null)
        results = await getBuildings(siteId)
        actions.setBuildings(results.data)
      } catch (e) {
        apiErrorHandler(e)
      }
    }

    actions.closeBuildingsLoader()
  }),

  onSiteSelected: thunkOn(
    (_, storeActions) => storeActions.site.setSelected,

    async (actions) => {
      await actions.getBuildings()
    }
  ),
}

export default Building
