import { action, thunk } from 'easy-peasy'
import { getQueues, getJobHistory, getJobLogs } from '@api/Services/OpenADR'
import { apiErrorHandler } from '@utilities/Error'

const OpenADRQueue = {
  optionsLoading: false,
  options: [],
  jobHistoryLoading: false,
  jobHistory: [],
  jobLogsLoading: false,
  jobLogs: [],
  selected: null,

  setOptions: action((state, response) => {
    state.options = response
  }),

  setOptionsLoading: action((state, payload) => {
    state.optionsLoading = payload
  }),

  setJobHistory: action((state, payload) => {
    state.jobHistory = payload
  }),

  setJobHistoryLoading: action((state, payload) => {
    state.jobHistoryLoading = payload
  }),

  setJobLogs: action((state, payload) => {
    state.jobLogs = payload
  }),

  setJobLogsLoading: action((state, payload) => {
    state.jobLogsLoading = payload
  }),

  setSelected: action((state, payload) => {
    state.selected = payload
  }),

  getOptions: thunk(async (actions) => {
    let results = []

    actions.setOptionsLoading(true)

    try {
      results = await getQueues()
      actions.setOptions(results.data ?? [])
    } catch (e) {
      apiErrorHandler(e)
    }

    actions.setOptionsLoading(false)
  }),

  getJobHistory: thunk(async (actions, _, { getState }) => {
    let queueId = getState().selected
    let results = []

    actions.setJobHistoryLoading(true)

    try {
      results = await getJobHistory(queueId)
      actions.setJobHistory(results.data ?? [])
    } catch (e) {
      apiErrorHandler(e)
    }

    actions.setJobHistoryLoading(false)
  }),

  getJobLogs: thunk(async (actions, jobId, { getState }) => {
    let queueId = getState().selected
    let results = []

    actions.setJobLogsLoading(true)

    try {
      results = await getJobLogs(queueId, jobId)
      actions.setJobLogs(results.data?.logs ?? [])
    } catch (e) {
      apiErrorHandler(e)
    }

    actions.setJobLogsLoading(false)
  }),
}

export default OpenADRQueue
