import moment from 'moment/moment'

/**
 * Format a unix timestamp into a string.
 * @param {number} unix Unix integer timestamp in milliseconds
 * @returns {string} Formatted string timestamp
 */
export const formatUnix = (unix) => {
  return unix ? moment.unix(unix / 1000).format('YYYY-MM-DD hh:mm:ss A') : null
}
