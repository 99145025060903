import React, { useState } from 'react'
import './ToggleSwitch.scss'

const ToggleSwitch = ({ initialState = false, onChange, children }) => {
  const [isChecked, setIsChecked] = useState(initialState)

  const handleToggle = (e) => {
    e.preventDefault()

    const newState = !isChecked
    setIsChecked(newState)
    if (onChange) {
      onChange(newState)
    }
  }

  return (
    <div className="toggle-switch">
      {children && <span className={`toggle-label`}>{children}</span>}
      <button className={`toggle-button ${isChecked ? '-active' : ''}`} onClick={handleToggle}>
        <span className={`toggle-circle ${isChecked ? '-active' : ''}`} />
      </button>
    </div>
  )
}

export default ToggleSwitch
