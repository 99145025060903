import { apiClient } from '../API'

/**
 * @typedef {object} Site
 * @property {number} ID Internal identifier
 * @property {string} Name Name
 * @property {string} Abbreviation Abbreviation
 * @property {string} PartnerID Partner identifier
 */

/**
 * Get sites for a given partner identifier.
 * @param {string} partnerId
 * @param {number[]} siteIds
 * @returns {Promise<Site[]>}
 */
export function getSites(partnerId, siteIds) {
  let config = {
    params: {
      partnerId,
      siteId: siteIds,
    },
    paramsSerializer: {
      indexes: null,
    },
  }

  return apiClient.get(`/point/site`, config)
}

/**
 * Get equipment based of ids.
 * @param {number[]} equipmentIds
 * @returns {Promise<Site[]>}
 */
export function getEquipment(equipmentIds) {
  let config = {
    params: {
      id: equipmentIds,
    },
    paramsSerializer: {
      indexes: null,
    },
  }

  return apiClient.get(`/point/equipment`, config)
}

/**
 * Get buildings for given site id.
 * @param {number} siteId
 * @returns {Promise<Site[]>}
 */
export function getBuildings(siteId) {
  return apiClient.get(`/point/site/${siteId}/building`)
}
