import { action, thunk } from 'easy-peasy'
import { getReports } from '../../API/Services/Report'
import { apiErrorHandler } from '../../Utilities/Error'

const Report = {
  records: [],
  recordsLoading: false,

  setRecords: action((state, payload) => {
    state.records = payload
  }),

  openRecordsLoader: action((state) => {
    state.recordsLoading = true
  }),

  closeRecordsLoader: action((state) => {
    state.recordsLoading = false
  }),

  getRecords: thunk(async (actions, _, { getStoreState }) => {
    let results = []
    actions.openRecordsLoader()

    let partnerId = getStoreState().partner.selected?.value

    if (partnerId) {
      try {
        results = await getReports(partnerId)
        actions.setRecords(
          results.data.map((r) => ({
            ...r,
            ...(r.lastRunCompleted && { lastRunCompleted: new Date(r.lastRunCompleted) }),
            ...(r.lastRunStarted && { lastRunStarted: new Date(r.lastRunStarted) }),
          }))
        )
      } catch (e) {
        apiErrorHandler(e)
      }
    }

    actions.closeRecordsLoader()
  }),
}

export default Report
