import { useState, useEffect } from 'react'
import DropdownField from './Form/DropdownField'
import { useStoreState, useStoreActions } from 'easy-peasy'
import './PartnerSelector.scss'

function PartnerSelector() {
  const [pendingForm, setPendingForm] = useState({
    selectedPartner: {},
  })
  const partnerOptions = useStoreState((state) => state.partner.dropdownOptions)
  const selectedPartner = useStoreState((state) => state.partner.selected)
  const setSelectedPartner = useStoreActions((actions) => actions.partner.setSelected)

  const handleSelectChange = (option, name) => {
    setPendingForm({
      ...pendingForm,
      [name]: option,
    })

    setSelectedPartner(option)
  }

  useEffect(() => {
    let form = {
      selectedPartner: selectedPartner,
    }

    setPendingForm(form)
  }, [selectedPartner])

  useEffect(() => {
    // nothing to do if partner options not initialized
    if (!partnerOptions) {
      return
    }

    // if no selected partner and partner options exist
    // or partner options don't contain current selection
    // default partner options to first option
    if ((!selectedPartner && partnerOptions.length) || !partnerOptions.some((p) => selectedPartner.value === p.value)) {
      setSelectedPartner(partnerOptions[0])
    }
  }, [selectedPartner, partnerOptions])

  // if user has only 1 partner
  if (partnerOptions?.length == 1) return

  return (
    <>
      <div className="container">
        {partnerOptions?.length && (
          <DropdownField
            onTop={true}
            name="selectedPartner"
            initial={selectedPartner}
            value={pendingForm.selectedPartner}
            onChange={handleSelectChange}
            placeholder="Default"
            options={partnerOptions}
            className="partner-selector"
          />
        )}
      </div>
      <hr className="separator-selector" />
    </>
  )
}

export default PartnerSelector
