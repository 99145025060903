import { forwardRef, useRef, useEffect, useMemo } from 'react'
import { useTable, usePagination, useRowSelect, useSortBy, useFilters, useColumnOrder } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortUp, faSortDown, faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { matchSorter } from 'match-sorter'
import { debounce } from 'lodash'

/**
 * Indeterminant checkbox for interaction with multi-row selection.
 */
const IndeterminateCheckbox = forwardRef(({ row, page, indeterminate, checked, inverted, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  // reflect indeterminant state
  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  // gate the onclick event
  const handleSelectionChangeAttempt = async (event) => {
    let checked = inverted ? !event.target.checked : event.target.checked
    let result = true

    if (result) {
      if (row) {
        row.toggleRowSelected(checked)
      } else {
        page(checked)
      }
    }
  }

  return (
    <>
      <input
        type="checkbox"
        ref={resolvedRef}
        checked={inverted ? !checked : checked}
        onChange={(event) => handleSelectionChangeAttempt(event)}
        {...rest}
      />
    </>
  )
})

IndeterminateCheckbox.displayName = 'Checkbox'

/**
 * Default column filter by text
 * @param {object} params
 * @param {object} params.column - react-table column object
 */
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <div className="header input">
      <FontAwesomeIcon
        icon={faSearch}
        style={{ paddingRight: '12px', color: '#485465', opacity: '50%', width: '15px' }}
      />
      <input
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
        }}
        placeholder={'Search'}
        style={{ border: 'none', outline: 'none' }}
      />
    </div>
  )
}

/**
 * Fuzzy string serach for column filtering
 * @param {object[]} rows - pre-filtered react-table rows
 * @param {string} id - Column accessor id
 * @param {string} filterValue - Desired filter value
 */
function fuzzyTextFilter(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// don't include anything that isn't defined
fuzzyTextFilter.autoRemove = (val) => !val

// default page site options
const pageSizeOptions = [15, 25, 50, 100]

/**
 * Callback for when the modal goes into a hidden state.
 * @callback persistOptionsCallback
 */

/**
 * Callback for when the modal goes into a hidden state.
 * @callback selectionChangeCallback
 */

/**
 * Callback for when the modal goes into a hidden state.
 * @callback fetchDataCallback
 */

/**
 * Table component built off of headless react-table
 * @param {object} params
 * @param {object[]} params.columns - react-table column configs
 * @param {object[]} params.data - Array of objects to drive the table
 * @param {boolean} params.loading - Toggles the loading view for the table
 * @param {number} params.initialPageSize - Default row count size to render the table and set selection
 * @param {string[]} params.initialHiddenColumns - List of columns to hide by id
 * @param {string[]} params.initialColumnOrder - Ordered list of columns by id
 * @param {persistOptionsCallback} params.persistOptions - Callback to for persisted table settings
 * @param {selectionChangeCallback} params.onSelectionChange - Callback after a row selection change
 * @param {boolean} params.singleSelect - Whether only one row can be selected at a time
 * @param {fetchDataCallback} params.fetchData - For server-side pagination, callback used to get data
 * @param {boolean} params.refreshData - For server-side pagination, used to indicate outdated data
 * @param {number} params.pageCount - For server-side pagination, total page count of data
 */
function Table({
  columns,
  data,
  loading,
  initialPageSize = pageSizeOptions[0],
  initialHiddenColumns = [],
  initialColumnOrder = [],
  persistOptions,
  onSelectionChange,
  onSelectionIdChange,
  singleSelect,
  fetchData,
  pageCount: controlledPageCount,
  invertSelection,
  getRowId,
  disableSelectionClear,
  loadingMessage = 'Loading...',
}) {
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilter,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize, filters, sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        pageSize: initialPageSize,
        hiddenColumns: initialHiddenColumns,
        columnOrder: initialColumnOrder,
        pageIndex: 0,
      },
      ...(singleSelect && {
        stateReducer: (newState, action) => {
          if (action.type === 'toggleRowSelected') {
            newState.selectedRowIds = {
              [action.id]: action.value,
            }
          }

          return newState
        },
      }),
      getRowId,
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSelectedRows: !disableSelectionClear,
      invertSelection: invertSelection,
      ...(fetchData && {
        manualPagination: true,
        pageCount: controlledPageCount,
        manualFilters: true,
        manualSortBy: true,
      }),
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          ...(!singleSelect && {
            Header: ({ toggleAllPageRowsSelected, getToggleAllPageRowsSelectedProps, invertSelection }) => (
              <div>
                <IndeterminateCheckbox
                  inverted={invertSelection}
                  page={toggleAllPageRowsSelected}
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
          }),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row, invertSelection }) => (
            <div>
              <IndeterminateCheckbox inverted={invertSelection} row={row} {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  // persist the page size when it's updated
  useEffect(() => {
    if (persistOptions) {
      persistOptions({ rowCount: pageSize })
    }
  }, [persistOptions, pageSize])

  // when row selection is updated pass that into the callback
  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedFlatRows.map((d) => d.original))
    }
  }, [selectedFlatRows, onSelectionChange])

  useEffect(() => {
    if (onSelectionIdChange) {
      onSelectionIdChange(Object.keys(selectedRowIds))
    }
  }, [selectedRowIds, onSelectionIdChange])

  // doesn't really work correctly but debound paginated calls
  const debouncedFetch = useMemo(
    () => (fetchData ? debounce(fetchData, 500, { maxWait: 1000, leading: true }) : null),
    [fetchData]
  )

  // request new data when page index, size, sorted column, or filters update
  useEffect(() => {
    if (debouncedFetch) {
      debouncedFetch({ pageIndex, pageSize, filters, sortBy })
    }
  }, [debouncedFetch, pageIndex, pageSize, filters, sortBy])

  // reset page index when we're on a page that doesn't exist
  useEffect(() => {
    if (pageIndex + 1 > pageOptions.length) {
      gotoPage(0)
    }
  }, [pageIndex, gotoPage, pageOptions.length])

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            /* eslint-disable react/jsx-key */
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column.id}>
                  <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        <FontAwesomeIcon
                          icon={column.isSortedDesc ? faSortDown : faSortUp}
                          style={{ marginLeft: '4px' }}
                        />
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          style={{ opacity: loading && loadingMessage === 'Loading...' ? '0.5' : undefined }}
        >
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <span style={{ marginLeft: '16px', marginRight: '16px' }}>
          Page{' '}
          <strong>
            <input
              id="cy_pagination"
              type="number"
              value={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '40px' }}
            />{' '}
            of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {pageSizeOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        {loading && (
          <>
            <FontAwesomeIcon pulse icon={faSpinner} style={{ marginLeft: '8px', marginRight: '4px' }} />
            {loadingMessage}
          </>
        )}
      </div>
    </>
  )
}

export default Table
