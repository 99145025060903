import { toast } from 'react-toastify'
import axios from 'axios'

export const apiErrorHandler = (error) => {
  if (axios.isCancel(error)) {
    return true
  } else if (error.edoWarning) {
    toast.warning(error.edoWarning)
  } else if (error.response?.data?.message) {
    toast.error(error.response.data.message)
  } else if (error.response?.data) {
    toast.error(error.response.data)
  } else if (error.response) {
    if (error.response.status === 403) {
      toast.error("You don't have sufficient access to use this application.")
    } else {
      toast.error(`${error.response.status}: ${error.response.statusText}`)
    }
  } else if (error.request) {
    toast.error('No response from server, try again later.')
  } else {
    console.log(error)
    toast('Unknown error.')
  }

  return false
}
