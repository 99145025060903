import React from 'react'
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom'
import EventPage from './Event'
import EventSchedulerPage from './EventScheduler'
import BuildingPage from './Building'
import TabSwitchSelector from '../../Components/TabSwitchSelector'

const ControlLayout = () => {
  const navigate = useNavigate()

  const handleTabSelect = (tab) => {
    if (tab === 'Events') {
      navigate('')
    } else if (tab === 'Buildings') {
      navigate('buildings')
    }
  }

  return (
    <div className="App-module">
      <TabSwitchSelector tabs={['Events', 'Buildings']} onSelect={handleTabSelect} />
      <Outlet />
    </div>
  )
}

/**
 * Control routes.
 */
const Control = () => {
  return (
    <Routes>
      <Route path="/" element={<ControlLayout />}>
        <Route path="/" element={<EventPage />} />
        <Route path="buildings" element={<BuildingPage />} />
      </Route>
      <Route path="scheduler" element={<EventSchedulerPage />} />
    </Routes>
  )
}

export default Control
