export const Display = Object.freeze({
  VTN: 1,
  VEN: 2,
})

// valid view options for the Switch Selector
export const options = [
  {
    id: Display.VTN,
    name: 'Virtual Top Nodes',
  },
  {
    id: Display.VEN,
    name: 'Virtual End Nodes',
  },
]

export const STATUS_CLASSES = {
  NOW: 'status-happening-now',
  SCHEDULED: 'status-scheduled',
  CANCELED: 'status-canceled',
  COMPLETED: 'status-completed',
}

export const STATUS_CONFIG = {
  NOW: {
    className: 'status-happening-now',
    allowActions: true,
  },
  SCHEDULED: {
    className: 'status-scheduled',
    allowActions: true,
  },
  CANCELED: {
    className: 'status-canceled',
    allowActions: false,
  },
  COMPLETED: {
    className: 'status-completed',
    allowActions: false,
  },
}
