import { action, thunk } from 'easy-peasy'
import { getBuildings, getBuildingIds, getEventData, cancelRequest } from '../../API/Services/Control'
import { toast } from 'react-toastify'

const Control = {
  buildingOptions: [],
  eventOptions: [],
  eventLoading: false,
  cancelEventLoading: false,

  closeEventLoader: action((state) => {
    state.eventLoading = false
  }),

  openEventLoader: action((state) => {
    state.eventLoading = true
  }),

  closeCancelEventLoader: action((state) => {
    state.cancelEventLoading = false
  }),

  openCancelEventLoader: action((state) => {
    state.cancelEventLoading = true
  }),

  setBuildingOptions: action((state, payload) => {
    state.buildingOptions = payload
  }),

  setEventOptions: action((state, payload) => {
    state.eventOptions = payload
  }),

  getBuildings: thunk(async (actions, username) => {
    let buildingIds = [],
      buildingResults = []

    try {
      // Get building Ids
      const { data: ids } = await getBuildingIds(username)
      buildingIds = Object.keys(ids)

      // Get building data
      const { data } = await getBuildings(buildingIds)

      buildingResults = data
    } catch (e) {
      console.log(e)
    }

    actions.setBuildingOptions(buildingResults)
  }),

  getEvents: thunk(async (actions, filters) => {
    try {
      actions.openEventLoader()
      const { data } = await getEventData(filters)

      const buildingIds = [...new Set(data.map((item) => item.buildingId))]
      // Get building data
      const { data: buildings } = await getBuildings(buildingIds)
      // Structure building object
      const buildingNames = buildings.reduce((acc, item) => {
        if (item.id && item.name) {
          acc[item.id] = item.name
        }
        return acc
      }, {})
      // Adding the names
      const events = data.map((item) => ({ ...item, buildingName: buildingNames[item.buildingId] }))

      actions.setEventOptions(events)
    } catch (e) {
      console.log(e)
    } finally {
      actions.closeEventLoader()
    }
  }),

  cancelEvent: thunk(async (actions, { id, reason }) => {
    try {
      actions.openCancelEventLoader()

      await cancelRequest(id, reason)

      toast.success(`Cancellation confirmed.`)
    } catch (e) {
      toast.error(`Cancellation error.`)
    } finally {
      actions.closeCancelEventLoader()
    }
  }),
}

export default Control
