import DropdownField from './Form/DropdownField'

const dropdownOptions = [
  {
    value: 1,
    label: '1 day',
  },
  {
    value: 30,
    label: '30 days',
  },
  {
    value: 180,
    label: '180 days',
  },
  {
    value: 365,
    label: '1 year',
  },
]

const ExpiryDropdown = ({ value, ...restProps }) => {
  return (
    <DropdownField
      label="Validity Period"
      name="validityPeriod"
      placeholder="Default"
      options={dropdownOptions}
      value={dropdownOptions.find((o) => o.value === value)}
      isClearable
      {...restProps}
    />
  )
}

export default ExpiryDropdown
