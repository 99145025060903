import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import './TimePicker.scss'

/**
 * Time input field
 * @param {object} params
 * @param {string} params.label - Label of the time field
 * @param {boolean} params.required - Whether or not this field is required
 * @param {string} params.initial - Initial time value
 * @param {string} params.value - Current time value
 * @param {function} params.onChange - Function to handle time change
 * @param {string} params.className - Additional CSS classes
 */
const TimePicker = ({ label, required, initial, value, onChange, className, ...restProps }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedTime, setSelectedTime] = useState(initial || '12:00 am')
  const [selectedHour, setSelectedHour] = useState('12')
  const [selectedMinute, setSelectedMinute] = useState('00')
  const [selectedPeriod, setSelectedPeriod] = useState('am')

  const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'))
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'))
  const periods = ['am', 'pm']

  const hourRef = useRef(null)
  const minuteRef = useRef(null)
  const periodRef = useRef(null)
  const pickerRef = useRef(null)

  const touched = value !== undefined && initial !== value

  useEffect(() => {
    const newTime = `${selectedHour}:${selectedMinute} ${selectedPeriod}`
    setSelectedTime(newTime)
    if (onChange) {
      onChange(newTime)
    }
  }, [selectedHour, selectedMinute, selectedPeriod, onChange])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleSelect = (value, setter, ref) => {
    setter(value)
    if (ref.current) {
      const index = Array.from(ref.current.children).findIndex((child) => child.textContent === value)
      ref.current.scrollTop = index * 40
    }
  }

  const renderScrollableList = (options, selected, setter, ref) => (
    <div className="scrollable-list" ref={ref}>
      {options.map((option) => (
        <div
          key={option}
          className={`option ${option === selected ? 'selected' : ''}`}
          onClick={() => handleSelect(option, setter, ref)}
        >
          {option}
        </div>
      ))}
    </div>
  )

  const togglePicker = () => setIsOpen(!isOpen)

  return (
    <div className={`form-field time-picker ${!value ? 'error' : ''}`} ref={pickerRef}>
      {label && <div className="title">{label + (required ? '*' : '')}</div>}
      <div className="input-wrapper time-picker__input-container">
        <input
          type="text"
          value={selectedTime}
          onClick={togglePicker}
          readOnly
          className={`time-picker__input ${className || ''} ${touched ? 'touched' : ''} has-suffix`}
          required={required}
          {...restProps}
        />
        <FontAwesomeIcon icon={faClock} className="input-suffix time-picker__icon" onClick={togglePicker} />
        {isOpen && (
          <div className="picker-dropdown">
            <div className="picker-content">
              {renderScrollableList(hours, selectedHour, setSelectedHour, hourRef)}
              {renderScrollableList(minutes, selectedMinute, setSelectedMinute, minuteRef)}
              {renderScrollableList(periods, selectedPeriod, setSelectedPeriod, periodRef)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TimePicker
