import { useStoreState } from 'easy-peasy'
import { Routes, Route } from 'react-router-dom'
import Overview from './Queue/Overview'
import Jobs from './Queue/Jobs'

const JobQueueManagement = () => {
  const selectedQueue = useStoreState((state) => state.openadrqueue.selected)

  return (
    <div className="App-module">
      <Routes>
        <Route path="" element={selectedQueue ? <Jobs /> : <Overview />} />
      </Routes>
    </div>
  )
}

export default JobQueueManagement
