import { useStoreActions, useStoreState } from 'easy-peasy'
import { useCallback, useState, useRef } from 'react'
import NodeEditForm from './NodeEditForm'
import EditModal from '@components/EditModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

/**
 * Modal that allows creating a new node
 */
function CreateNodeModal({ defaultModel, show, onToggle }) {
  // -- LOCAL STATE --
  const [pendingForm, setPendingForm] = useState(defaultModel)
  const submitHTML = useRef(null)

  // -- GLOBAL STATE --
  const loading = useStoreState((state) => state.openadr.createNodeLoading)
  const setRefreshNodes = useStoreActions((actions) => actions.openadr.setRefreshNodes)

  // -- GLOBAL ACTIONS --
  const createNode = useStoreActions((actions) => actions.openadr.createNode)

  /**
   * Handler for updating the pending object based on key reference.
   * @param {Object} payload - Object values to overwrite existing pending keys
   */
  const handleRawChange = (payload) => {
    setPendingForm({
      ...pendingForm,
      ...payload,
    })
  }

  /**
   * Handler for updating input field changes in the pending object.
   * @param {Event} event - Event on the form field update.
   */
  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setPendingForm({
      ...pendingForm,
      [name]: value,
    })
  }

  /**
   * Toggle the modal state to hidden/visible and reset the pending form to default
   */
  const toggleModal = useCallback(() => {
    setPendingForm(defaultModel)
    onToggle()
  }, [setPendingForm, onToggle, defaultModel])

  /**
   * Handler for dropdown select updates on the pending object.
   * @param {Object} option - Selected option from the dropdown field
   * @param {string} name - Name of the field getting updated by the dropdown
   */
  const handleSelectChange = (option, name) => {
    const value = option?.value ?? null

    setPendingForm({
      ...pendingForm,
      [name]: value,
    })
  }

  /**
   * Handler for submitting the form, creating a new node.
   */
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()

      let payload = {
        name: pendingForm.name,
        deviceId: pendingForm.deviceId,
        typeId: pendingForm.typeId,
      }

      // post the form and refresh the node options
      createNode(payload).then(() => {
        setRefreshNodes(true)
        toggleModal()
      })
    },
    [createNode, toggleModal, pendingForm]
  )

  return (
    <EditModal
      title="Create New Node"
      show={show}
      onHide={toggleModal}
      autoFocus={false}
      onSubmit={() => {
        submitHTML.current.click()
      }}
    >
      {loading && <FontAwesomeIcon pulse icon={faSpinner} className="loading" />}
      <form onSubmit={handleSubmit}>
        <NodeEditForm
          loading={loading}
          node={pendingForm}
          onChange={handleInputChange}
          onSelectChange={handleSelectChange}
          onCoordinateChange={handleRawChange}
          onRawChange={handleRawChange}
          hideOadrIdField
        />
        <input ref={submitHTML} type="submit" style={{ display: 'none' }} />
      </form>
    </EditModal>
  )
}

export default CreateNodeModal
