import UserManagementBaseCard from '@components/UserManagement/UserManagementBaseCard'
import TextField from '@components/Form/TextField'
import { faUser } from '@fortawesome/free-solid-svg-icons'

const PersonalInformationCard = (props) => {
  // destructure props
  const { pendingForm, initial, action } = props

  // construct card-specific form rows
  const formRows = (
    <div className="form">
      <div className="row">
        <TextField
          name="username"
          label="Email"
          type="email"
          required
          initial={initial && initial?.username}
          value={pendingForm?.username}
          onChange={props.onChange}
          disabled={action === 'edit' ? true : false}
        />
      </div>
      <div className="row">
        <TextField
          name="firstName"
          label="First Name"
          required
          initial={initial && initial?.firstName}
          value={pendingForm?.firstName}
          onChange={props.onChange}
        />
        <TextField
          name="lastName"
          label="Last Name"
          required
          initial={initial && initial?.lastName}
          value={pendingForm?.lastName}
          onChange={props.onChange}
        />
      </div>
      <div className="row">
        <TextField
          name="phone"
          label="Phone Number"
          type="tel"
          pattern="\+1[0-9]{10}"
          onInvalid={(e) => e.target.setCustomValidity('Please match the following format: +11234567890')}
          onInput={(e) => e.target.setCustomValidity('')}
          optionalLabel
          initial={initial && initial?.phone}
          value={pendingForm?.phone}
          onChange={props.onChange}
        />
      </div>
    </div>
  )

  return <UserManagementBaseCard icon={faUser} groupName={'Personal Information'} formRows={formRows} />
}

export default PersonalInformationCard
