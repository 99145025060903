import { useCallback, useEffect, useMemo, useState } from 'react'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import Table from '@components/Table/Table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SiteSelector from '../../Components/SiteSelector'
import MonitorChart from '../../Components/MonitorChart'
import { useStoreState } from 'easy-peasy'
import { getEventRuns, getEventWrites, getMonitorPoints } from '../../API/Services/Control'
import { apiErrorHandler } from '../../Utilities/Error'
import { getEquipment } from '../../API/Services/Point'
import { Link } from 'react-router-dom'
import './Event.scss'

const Event = (props) => {
  const [fetchingRuns, setFetchingRuns] = useState(false)
  const [fetchingEquipment, setFetchingEquipment] = useState(false)
  const [fetchingTelemetry, setFetchingTelemetry] = useState(false)
  const [fetchingWrites, setFetchingWrites] = useState(false)

  const [eventRuns, setEventRuns] = useState([])
  const [selectedRun, setSelectedRun] = useState(null)
  const [eventEquipment, setEventEquipment] = useState([])
  const [telemetry, setTelemetry] = useState([])
  const [selectedEquipment, setSelectedEquipment] = useState(null)
  const [eventWrites, setEventWrites] = useState([])
  const selectedSite = useStoreState((state) => state.site.selected)

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'eventName',
        filter: 'fuzzyText',
      },
      {
        Header: 'Creator',
        accessor: 'initiator',
        filter: 'fuzzyText',
      },
      {
        Header: 'Start',
        accessor: 'startDate',
        Cell: (props) => props.value.toLocaleString(),
        disableFilters: true,
      },
      {
        Header: 'End',
        accessor: 'endDate',
        Cell: (props) => props.value.toLocaleString(),
        disableFilters: true,
      },
      {
        Header: 'Canceled By',
        accessor: 'canceledBy',
        disableFilters: true,
      },
      {
        Header: 'Canceled At',
        accessor: 'canceledAt',
        Cell: (props) => props.value?.toLocaleString(),
        disableFilters: true,
      },
    ],
    []
  )

  const equipmentTableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        filter: 'fuzzyText',
      },
      {
        Header: 'Type',
        accessor: 'typeName',
        filter: 'fuzzyText',
      },
    ],
    []
  )

  const handleSelectionChange = useCallback(
    (selection) => {
      if (selection.length > 0) {
        setSelectedRun(selection[0])
      } else {
        setSelectedRun(null)
      }
    },
    [setSelectedRun]
  )

  const handleEquipmentSelectionChange = useCallback(
    (selection) => {
      if (selection.length > 0) {
        setSelectedEquipment(selection[0])
      } else {
        setSelectedEquipment(null)
      }
    },
    [setSelectedEquipment]
  )

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSelectedRun(null)
        setFetchingRuns(true)
        const eventRunResults = await getEventRuns(selectedSite.value)
        setEventRuns(
          eventRunResults.data.map((x) => ({
            ...x,
            startDate: new Date(x.startDate),
            endDate: new Date(x.endDate),
            canceledAt: x.canceledAt ? new Date(x.canceledAt) : '',
          }))
        )
      } catch (e) {
        apiErrorHandler(e)
      }
      setFetchingRuns(false)
    }
    if (selectedSite) {
      fetchData()
    }
  }, [selectedSite])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSelectedEquipment(null)
        setFetchingEquipment(true)
        let eventEquipment = []
        if (selectedRun.equipmentIds.length) {
          eventEquipment = (await getEquipment(selectedRun.equipmentIds)).data
        }
        setEventEquipment(eventEquipment)
      } catch (e) {
        console.log(e)
        apiErrorHandler(e)
      }
      setFetchingEquipment(false)
    }
    if (selectedRun) {
      fetchData()
    }
  }, [selectedRun])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingWrites(true)
        const eventWrites = (await getEventWrites(selectedRun.id)).data

        const eventWriteMap = eventWrites.reduce((out, write) => {
          const pointWrites = out[write.pointId] ?? { data: [], ids: [] }

          pointWrites.data.push(
            { x: new Date(write.startTime), y: write.operationValue },
            { x: new Date(write.endTime), y: write.operationValue }
          )
          pointWrites.ids.push(write.id, write.id)

          out[write.pointId] = pointWrites
          return out
        }, {})

        setEventWrites(eventWriteMap)
      } catch (e) {
        console.log(e)
        apiErrorHandler(e)
      }
      setFetchingWrites(false)
    }
    if (selectedRun) {
      fetchData()
    }
  }, [selectedRun])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingTelemetry(true)
        const relevantPoints = await getMonitorPoints(selectedEquipment.id, selectedRun.eventId)

        setTelemetry(relevantPoints.data)
      } catch (e) {
        console.log(e)
        apiErrorHandler(e)
      }
      setFetchingTelemetry(false)
    }
    if (selectedEquipment && selectedRun) {
      fetchData()
    }
  }, [selectedEquipment, selectedRun])

  return (
    <div className="App-submodule">
      <div className="App-submodule-header">
        <SiteSelector />
        <Link to="/event/scheduler" className="button scheduler-button">
          <FontAwesomeIcon icon={faCirclePlus} width={15} height={15} className="schedule-event-icon" />
          <span>SCHEDULE EVENT</span>
        </Link>
      </div>
      <div className="row" style={{ alignItems: 'flex-start' }}>
        <div style={{ flexGrow: 1 }}>
          <h3>Event Runs</h3>
          <Table
            singleSelect
            loading={fetchingRuns}
            columns={tableColumns}
            data={eventRuns}
            onSelectionChange={handleSelectionChange}
          />
        </div>
        {selectedRun && (
          <div style={{ paddingLeft: '10px' }}>
            <h3>Equipment</h3>
            <Table
              singleSelect
              loading={fetchingEquipment}
              columns={equipmentTableColumns}
              data={eventEquipment}
              onSelectionChange={handleEquipmentSelectionChange}
            />
          </div>
        )}
      </div>
      {selectedRun &&
        selectedEquipment &&
        telemetry.map((x) => (
          <MonitorChart
            key={x.ID}
            pointId={x.ID}
            pointType={x.PointClassName}
            startDate={selectedRun.startDate}
            endDate={selectedRun.endDate}
            writes={eventWrites[x.ID]}
          />
        ))}
    </div>
  )
}

export default Event
