import { faArrowLeft, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useEffect } from 'react'
import JobHistory from '@components/JobHistory'
import Loader from '@components/Loader'

/**
 * Queue jobs view.
 */
const Jobs = () => {
  const jobHistoryLoading = useStoreState((state) => state.openadrqueue.jobHistoryLoading)
  const getJobHistory = useStoreActions((actions) => actions.openadrqueue.getJobHistory)
  const setSelectedQueue = useStoreActions((actions) => actions.openadrqueue.setSelected)
  const selectedQueue = useStoreState((state) => state.openadrqueue.selected)

  // Hook to get data utilized on detail page from queue when queue is selected
  useEffect(() => {
    if (selectedQueue) {
      getJobHistory(selectedQueue)
    }
  }, [selectedQueue])

  return (
    <div>
      <div className="nav">
        <div className="selection-return" onClick={() => setSelectedQueue(null)}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ paddingRight: '8px' }} />
          <div style={{ marginRight: '16px', display: 'inline' }}>All Queues</div>
        </div>
      </div>
      {jobHistoryLoading ? (
        <Loader />
      ) : (
        selectedQueue && (
          <>
            <div className="context-header">
              <div className="title">
                <FontAwesomeIcon icon={faLayerGroup} style={{ paddingRight: '16px', fontSize: '44px' }} />
                {selectedQueue} (100 most recent jobs)
              </div>
            </div>
            <div className="table">
              <JobHistory />
            </div>
          </>
        )
      )}
    </div>
  )
}
export default Jobs
