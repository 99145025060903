import { useCallback, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import CreateModal from './CreateModal'
import { useNavigate } from 'react-router-dom'
import Button from '@components/Form/Button'

// default service certificate model
const defaultData = {
  displayName: '',
  description: '',
  permissions: [
    {
      resource: 'service',
      values: [],
    },
  ],
}

function CreateButton({ refreshCertificates, selectedTab }) {
  const [showEditModal, setShowEditModal] = useState(false)
  const navigate = useNavigate()

  const toggleModal = useCallback(() => {
    if (selectedTab === 'services') {
      // toggle modal if services is the selected tab
      setShowEditModal(!showEditModal && selectedTab === 'services')
    } else if (selectedTab === 'users') {
      // open add user page if users is the selected tab
      navigate('/users/add')
    }
  }, [setShowEditModal, showEditModal])

  return (
    <>
      <div className="actions" style={{ gap: 'none' }}>
        <Button
          customClassNames="add"
          label="Create New"
          icon={
            <FontAwesomeIcon
              class="icon"
              icon={faPlusCircle}
              style={{ float: 'left', cursor: 'pointer', paddingRight: '8px' }}
            />
          }
          solid={true}
          onClick={toggleModal}
        />
      </div>
      <CreateModal
        refreshCertificates={refreshCertificates}
        show={showEditModal}
        onToggle={toggleModal}
        defaultModel={defaultData}
      />
    </>
  )
}

export default CreateButton
