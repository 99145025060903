import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'

/**
 * Boolean form field that currently doesn't have any editability.
 * @param {Object} params
 * @param {string} params.label - Form field label
 * @param {boolean} params.required - Toggle for a required field, add an * for indication
 * @param {boolean} params.checked - Current state of the field
 * @param {boolean} params.indeterminate - Flags indeterminate state
 */
function BoolField({ label, required, checked, indeterminate, onClick, ...restProps }) {
  return (
    <div {...restProps}>
      <FontAwesomeIcon
        icon={checked ? (indeterminate ? faMinusSquare : faCheckSquare) : faSquare}
        style={{ marginRight: '4px', cursor: 'pointer' }}
        onClick={onClick}
      />
      {label && <label className="title">{label + (required ? '*' : '')}</label>}
    </div>
  )
}

export default BoolField
