import { apiClient } from '../API'

/**
 * @typedef {object} Node
 * @property {number} id Primary identifier
 * @property {string} oadrId OpenADR node identifier
 * @property {string} deviceId Device identifier
 * @property {string} name Name
 * @property {number} typeId OpenADR node type abbreviation
 * @property {string} typeAbbreviation OpenADR node type abbreviation
 * @property {string} typeName OpenADR node type name
 * @property {boolean} foreign Flag for remotely registered node
 * @property {NodeRegistration[]} registrations Other nodes this node is registered to
 */

/**
 * @typedef {object} NodeRegistration
 * @property {string} name Name
 * @property {string} oadrId OpenADR node identifier
 */

/**
 * @typedef {object} NodeType
 * @property {number} id Primary identifier
 * @property {string} abbreviation OpenADR node type abbreviation
 * @property {string} name OpenADR node type name
 */

/**
 * @typedef {object} RegisterVENtoVTNObject
 * @property {string} nodeName
 * @property {string} transportAddress
 * @property {boolean} reportOnly
 */

/**
 * @typedef {object} CreateNodeObject
 * @property {string} name Name
 * @property {string} deviceId Device identifier
 * @property {number} typeId OpenADR node type abbreviation
 */

/**
 * Gets OpenADR nodes.
 * @param {Object} filters - Filters to apply to endpoint
 * @returns {Promise<Node[]>}
 */
export function getNodes(filters) {
  let config = {
    params: filters,
    paramsSerializer: {
      indexes: null,
    },
  }

  return apiClient.get(`/openadr/admin/node`, config)
}

/**
 * Gets OpenADR node types supported in database.
 * @returns {Promise<NodeType[]>}
 */
export function getNodeTypes() {
  return apiClient.get(`/openadr/admin/node/type`)
}

/**
 * Post an OpenADR node.
 * @param {CreateNodeObject} payload - Node characteristics
 */
export function postNode(payload) {
  return apiClient.post(`/openadr/admin/node`, payload)
}

/**
 * Get queues and job counts.
 */
export const getQueues = () => {
  return apiClient.get(`/openadr/admin/queue`)
}

/**
 * Get queue job config and history.
 * @param {string} id Queue id
 */
export const getJobHistory = (id) => {
  return apiClient.get(`/openadr/admin/queue/${id}/job`)
}

/**
 * Get queue job logs.
 * @param {string} queueId Queue id
 * @param {string} jobId Job id
 */
export const getJobLogs = (queueId, jobId) => {
  return apiClient.get(`/openadr/admin/queue/${queueId}/job/${jobId}/log`)
}

/**
 * Post an VEN registration.
 * @param {number} nodeId
 * @param {RegisterVENtoVTNObject} payload
 */
export function postRegisterVENtoVTN(nodeId, payload) {
  return apiClient.post(`/openadr/admin/node/${nodeId}/registration`, payload)
}
