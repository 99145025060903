import { useStoreActions, useStoreState } from 'easy-peasy'
import { useEffect } from 'react'
import Loader from '@components/Loader'
import Tile from '@components/QueueTile/Tile'

/**
 * Queue list view
 */
const Overview = () => {
  // --- LOCAL STATE ---

  // --- GLOBAL STATE ---
  const queuesLoading = useStoreState((state) => state.openadrqueue.optionsLoading)
  const queues = useStoreState((state) => state.openadrqueue.options)

  // --- GLOBAL ACTIONS ---
  const getQueueOptions = useStoreActions((actions) => actions.openadrqueue.getOptions)
  const setSelectedQueue = useStoreActions((actions) => actions.openadrqueue.setSelected)

  // get queues
  useEffect(() => {
    getQueueOptions()
  }, [])

  // refresh queues on page load and every minute after that
  useEffect(() => {
    getQueueOptions()
    const interval = setInterval(getQueueOptions, 60000)
    return () => clearInterval(interval)
  }, [getQueueOptions])

  return (
    <div className="App-submodule">
      {queuesLoading ? (
        <Loader />
      ) : (
        queues.map((q) => (
          <Tile name={q.name} counts={q.counts} key={`queue-${q.name}`} onClick={() => setSelectedQueue(q.name)} />
        ))
      )}
    </div>
  )
}
export default Overview
