import { action, thunk, thunkOn } from 'easy-peasy'
import { getSites } from '../../API/Services/Point'
import { apiErrorHandler } from '../../Utilities/Error'

const Site = {
  selected: null,
  records: null,
  recordsLoading: false,

  setSelected: action((state, payload) => {
    state.selected = payload
  }),

  setRecords: action((state, payload) => {
    state.records = payload
  }),

  openRecordsLoader: action((state) => {
    state.recordsLoading = true
  }),

  closeRecordsLoader: action((state) => {
    state.recordsLoading = false
  }),

  getRecords: thunk(async (actions, _, { getStoreState }) => {
    let results = []
    actions.openRecordsLoader()

    let partnerId = getStoreState().partner.selected?.value

    if (partnerId) {
      try {
        results = await getSites(partnerId)
        actions.setRecords(results.data)
      } catch (e) {
        apiErrorHandler(e)
      }
    }

    actions.closeRecordsLoader()
  }),

  onPartnerUpdate: thunkOn(
    (_, storeActions) => storeActions.partner.setSelected,

    async (actions) => {
      await actions.getRecords()
    }
  ),
}

export default Site
