import { apiClient } from '../API'

/**
 * Get report for a given partner identifier.
 * @param {string} partnerId
 * @returns {Promise<Report[]>}
 */
export function getReports(partnerId) {
  let config = {
    params: {
      partnerId,
    },
  }

  return apiClient.get(`/report/`, config)
}
