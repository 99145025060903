import './NavigationPath.scss'
import { Outlet, Link } from 'react-router-dom'

/**
 * Navigation path showing {partner name} / {previous page name}
 * @param {object} props
 * @param {string} props.partner - Selected partner name
 * @param {string} props.text - Page name to be displayed
 */
function NavigationPath(props) {
  return (
    <div className="App-module">
      <h3 className="nav-path">
        <Link to="/">{props.partner?.label}</Link> / <Link to="..">{props.text}</Link>
      </h3>
      <Outlet />
    </div>
  )
}

export default NavigationPath
