import { useCallback, useMemo, useEffect, useState } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import Table from '@components/Table/Table'
import PendingNode from './PendingNode'
import CreateNodeButton from '@components/Node/CreateNodeButton'
import { map } from 'lodash'

const EditNode = (props) => {
  // Local state
  const [pendingForm, setPendingForm] = useState(null)

  // -- GLOBAL STATE --
  const nodes = useStoreState((state) => state.openadr.nodes)
  const nodesLoading = useStoreState((state) => state.openadr.nodesLoading)
  const getNodes = useStoreActions((actions) => actions.openadr.getNodes)
  const defaultNodeTableSettings = useStoreState((state) => state.openadr.tableSettings)
  const refreshNodes = useStoreState((state) => state.openadr.refreshNodes)
  const setRefreshNodes = useStoreActions((actions) => actions.openadr.setRefreshNodes)

  useEffect(() => {
    getNodes({
      typeId: props.display,
      withRegistrations: true,
    })
  }, [props.display, refreshNodes])

  useEffect(() => {
    setRefreshNodes(false)
  }, [nodes])

  /**
   * Handler for selected row updates to be passed to table
   * @param {Object[]} selection - Selected row
   */
  const handleSelectionChange = useCallback(
    (selection) => {
      props.onSetSelection(selection)
    },
    [props.onSetSelection]
  )

  // -- TABLE SETTINGS --
  // all valid column options for certificates
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        filter: 'fuzzyText',
      },
      {
        Header: 'Device ID',
        accessor: 'deviceId',
        filter: 'fuzzyText',
      },
      {
        Header: 'OpenADR ID',
        accessor: 'oadrId',
        filter: 'fuzzyText',
      },
      {
        Header: 'Registrations',
        id: 'registrations',
        accessor: (data) => {
          return (
            <ul>
              {map(data.registrations, (x) => (
                <li key={x.name}>{x.name + ': ' + x.oadrId} </li>
              ))}
            </ul>
          )
        },
        disableFilters: true,
      },
    ],
    [nodes]
  )

  useEffect(() => {
    let dirty = false
    if (pendingForm) {
      if (JSON.stringify(props.selection[0]) !== JSON.stringify(pendingForm)) {
        dirty = true
      }
    }

    if (dirty && !props.dirtyForm) {
      props.setDirtyForm(true)
    } else if (!dirty && props.dirtyForm) {
      props.setDirtyForm(false)
    }
  }, [props.selection, props.dirtyForm, pendingForm, props.setDirtyForm])

  return (
    <>
      <div className="header">
        <h2>Node Management</h2>
      </div>
      <div className="table">
        <div className="row" style={{ justifyContent: 'flex-start' }}>
          {props.SwitchSelector}
          <div style={{ marginLeft: 'auto' }}>
            <CreateNodeButton />
          </div>
        </div>
        <Table
          singleSelect
          validateNavigation={props.dirtyForm}
          loading={nodesLoading}
          columns={tableColumns}
          data={nodes}
          initialPageSize={defaultNodeTableSettings.rowCount}
          onSelectionChange={handleSelectionChange}
        />
      </div>
      <div>
        <PendingNode
          {...props}
          selection={props.selection}
          setPendingForm={setPendingForm}
          pendingForm={pendingForm}
          display={props.display}
        />
      </div>
    </>
  )
}

export default EditNode
