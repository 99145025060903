import { useCallback, useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import BaseModal from './BaseModal'
import TextField from './Form/TextField'
import BoolField from './Form/BoolField'
import Button from './Form/Button'
import Helpers from '@utilities/Helpers'
import { Display } from '@utilities/Constants'

function RegisterVENtoVTNModal({ node, ...props }) {
  /**
   * Local states
   */
  const [showModal, setShowModal] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [pendingForm, setPendingForm] = useState({
    reportOnly: false,
    transportAddress: '',
    nodeName: '',
  })

  const registerVENtoVTNLoading = useStoreState((state) => state.openadr.registerVENtoVTNLoading)
  const registerVENtoVTN = useStoreActions((actions) => actions.openadr.registerVENtoVTN)
  const getNodes = useStoreActions((actions) => actions.openadr.getNodes)

  /**
   * Handler for updating input field changes
   * @param {Event} event - Event data comming from the component
   */
  const handleInputChange = (event) => {
    const { name, value } = Helpers.getNameValueFromInput(event)

    setPendingForm({
      ...pendingForm,
      [name]: value,
    })
  }

  /**
   * Handler for updating the boolfield changes
   * @param {Object} payload - Object values to overwrite existing pending keys
   */
  const handleOverrideToggle = (payload) => {
    setPendingForm({
      ...pendingForm,
      [payload.name]: !payload.value,
    })
  }

  /**
   * Handler for submitting the form to the endpoint
   */
  const handleSubmit = () => {
    registerVENtoVTN({ ...pendingForm, id: node.id })
      .then(async () => {
        // if successfully close the modal and refresh the data
        await getNodes({
          typeId: Display.VEN,
          withRegistrations: true,
        })
        toggleModal()
      })
      .catch((err) => {
        // if something fails show the error
        setErrorMessage(err.response.data.message)
        setShowErrorMessage(true)
      })
  }

  /**
   * Handler to reset component states
   */
  const resetStates = () => {
    setErrorMessage('')
    setShowErrorMessage(false)
    setPendingForm({
      reportOnly: false,
      transportAddress: '',
      nodeName: '',
    })
  }

  /**
   * Handler to show/hide the modal
   */
  const toggleModal = useCallback(() => {
    resetStates()
    setShowModal(!showModal)
  }, [setShowModal, showModal])

  /**
   * If expeccting some response, clear the error message
   */
  useEffect(() => {
    setErrorMessage('')
  }, [registerVENtoVTNLoading])

  return (
    <>
      <Button
        label="Register VEN to VTN"
        onClick={toggleModal}
        style={{ marginTop: '12px', cursor: 'pointer', width: 150 }}
      />
      <BaseModal
        title="Register VEN to VTN"
        show={showModal}
        onHide={toggleModal}
        autoFocus={false}
        onSubmit={handleSubmit}
        loading={registerVENtoVTNLoading}
        disableSubmit={pendingForm.transportAddress == '' || pendingForm.nodeName == ''}
        {...props}
      >
        <div className={'container container-show-errors' + (props.loading ? ' disabled' : '')}>
          <div className="row">
            <TextField
              required
              label="Node Name"
              name="nodeName"
              value={pendingForm.nodeName}
              onChange={handleInputChange}
            />
            <TextField
              required
              label="Transport Address"
              name="transportAddress"
              value={pendingForm.transportAddress}
              onChange={handleInputChange}
            />
            <BoolField
              label="Report Only"
              name="reportOnly"
              checked={pendingForm.reportOnly}
              onClick={() => handleOverrideToggle({ value: pendingForm.reportOnly, name: 'reportOnly' })}
            />
          </div>
          {showErrorMessage && (
            <div className="row" style={{ color: '#eb516d' }}>
              <span>{errorMessage}</span>
            </div>
          )}
        </div>
      </BaseModal>
    </>
  )
}

export default RegisterVENtoVTNModal
