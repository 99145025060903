import { useStoreActions } from 'easy-peasy'

let resolveCallback

function useConfirm() {
  const showConfirm = useStoreActions((actions) => actions.general.openConfirmNavigationModal)
  const hideConfirm = useStoreActions((actions) => actions.general.closeConfirmNavigationModal)

  const onConfirm = () => {
    hideConfirm()
    resolveCallback(true)
  }

  const onCancel = () => {
    hideConfirm()
    resolveCallback(false)
  }

  const confirm = () => {
    showConfirm()
    return new Promise((resolve) => {
      resolveCallback = resolve
    })
  }

  return { confirm, onConfirm, onCancel }
}

export default useConfirm
