import { apiClient } from '../API'

/**
 * Get sites for a given partner identifier.
 * @param {string} partnerId
 * @returns {Promise<Site[]>}
 */
export function getRollup(pointId, startDate, endDate) {
  let config = {
    params: {
      id: pointId,
      begin: startDate,
      end: endDate,
    },
    paramsSerializer: {
      indexes: null,
    },
  }

  return apiClient.get(`/timeseries/rollup/1/minute`, config)
}
