import { faBuilding, faFile, faPerson, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { useStoreState } from 'easy-peasy'
import OverviewElement from '../Components/OverviewElement/OverviewElement'

const Overview = () => {
  const currentPartner = useStoreState((state) => state.partner.selected)

  return (
    <div className="App-submodule">
      <h1>{currentPartner?.label}</h1>
      <OverviewElement icon={faPerson} link="accounts" text="Accounts" />
      {currentPartner?.value === 'edo' && <OverviewElement icon={faBuilding} link="openadr" text="OpenADR" />}
      <OverviewElement icon={faWandSparkles} link="control" text="Control" />
      <OverviewElement icon={faFile} link="report" text="Report" />
    </div>
  )
}

export default Overview
