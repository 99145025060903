import './SwitchSelector.scss'

/**
 * Switch type selector that can be in one of n states.
 * @param {object} props
 * @param {object[]} props.options - Array of the select options
 */
function SwitchSelector(props) {
  return (
    <div className="wrapper">
      {props.options.map((x) => (
        <div
          key={`page-item-${x.id}`}
          className={`item${x.id === props.value ? ' selected' : ''}`}
          onClick={() => props.onSelect(x.id)}
        >
          {x.name}
        </div>
      ))}
    </div>
  )
}

export default SwitchSelector
