import { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { getRollup } from '../API/Services/Timeseries'
import { apiErrorHandler } from '../Utilities/Error'
import { BackgroundAreaFill } from '../Utilities/ChartPlugins'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js'
import 'chartjs-adapter-luxon'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  BackgroundAreaFill
)

const MonitorChart = ({ pointId, pointType, startDate, endDate, writes }) => {
  const [fetchingData, setFetchingData] = useState(false)
  const [data, setData] = useState(null)
  const [refreshTimeout, setRefreshTimeout] = useState(null)

  const fetchData = useCallback(async () => {
    let endDateAdjusted, startDateAdjusted
    try {
      setFetchingData(true)
      // start fetching 15 minutes prior to event start
      startDateAdjusted = new Date(startDate.getTime() - 1000 * 60 * 15)
      // end fetching 15 minutes after event end
      endDateAdjusted = new Date(endDate.getTime() + 1000 * 60 * 15)

      // build labels series with 1 minute interval
      let curDate = new Date(startDateAdjusted)
      const labels = []
      while (curDate <= endDateAdjusted) {
        labels.push(curDate)
        curDate = new Date(curDate.getTime() + 1000 * 60)
      }

      const monitorData = await getRollup(pointId, startDateAdjusted.toISOString(), endDateAdjusted.toISOString())
      // create the base chart data series for the monitor point
      const chartData = {
        labels,
        datasets: [
          {
            label: pointId,
            data: monitorData.data.map((x) => ({ x: new Date(x.ts.split(' ').join('T') + 'Z'), y: x.value })),
            borderColor: 'rgb(30, 93, 240)',
            backgroundColor: 'rgba(30, 93, 240, 0.5)',
          },
        ],
        cubicInterpolationMode: 'monotone',
      }

      // if writes associated with this point, add a series for them
      if (writes) {
        chartData.datasets.unshift({
          label: 'Write',
          data: writes.data,
          borderDash: [6, 6],
          borderColor: 'rgb(255, 107, 107)',
          backgroundColor: 'rgba(255, 107, 107, 0.5)',
        })
      }
      setData(chartData)
    } catch (e) {
      apiErrorHandler(e)
    }

    if (endDateAdjusted > new Date() && startDateAdjusted < new Date()) {
      // if end date is in the future and start date is less than now, live refresh the chart
      setRefreshTimeout(setTimeout(fetchData, 10000))
    } else if (startDateAdjusted > new Date()) {
      // if start date is greater than now, start refreshing at start period
      setRefreshTimeout(setTimeout(fetchData, startDateAdjusted.getTime() - new Date().getTime()))
    } else {
      // otherwise clear the refresh timeout
      setRefreshTimeout(null)
    }

    // close the loader
    setFetchingData(false)
  }, [pointId, startDate, endDate, setRefreshTimeout])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: pointType,
      },
      backgroundAreaFill: {
        windowStart: startDate,
        windowEnd: endDate,
      },
    },
  }

  useEffect(() => {
    if (pointId) {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout)
      }
      fetchData()
    } else {
      console.log(`Didn't fetch ${pointId}`)
    }
  }, [pointId, fetchData])

  return <div>{data && <Line height={'200px'} options={options} data={data} />}</div>
}

export default MonitorChart
