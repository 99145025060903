import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

function EditBox({ label, children, onSubmit, onCancel, onDelete, disableSubmit }) {
  return (
    <div className="edit-box">
      <div className="header">{label}</div>
      <div className="content">{children}</div>
      <div className="footer">
        {onDelete && (
          <div className="button no-fill" onClick={onDelete}>
            <FontAwesomeIcon icon={faTrash} style={{ paddingRight: '4px' }} />
            Delete
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="button no-fill" onClick={onCancel}>
            Cancel
          </div>
          {onSubmit && (
            <div
              className={'button ' + (disableSubmit ? 'button-disabled' : '')}
              onClick={!disableSubmit ? onSubmit : undefined}
            >
              Save
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditBox
