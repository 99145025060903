import { action, thunk } from 'easy-peasy'
import { getNodes, getNodeTypes, postNode, postRegisterVENtoVTN } from '@api/Services/OpenADR'
import { apiErrorHandler } from '@utilities/Error'
import { toast } from 'react-toastify'

const OpenADR = {
  nodes: [],
  nodesLoading: false,
  nodeTypesLoading: false,
  createNodeLoading: false,
  registerVENtoVTNLoading: false,
  refreshNodes: false,
  nodeTypes: [],
  tableSettings: {
    rowCount: 15,
  },

  setNodes: action((state, payload) => {
    state.nodes = payload
  }),

  openNodesLoader: action((state) => {
    state.nodesLoading = true
  }),

  closeNodesLoader: action((state) => {
    state.nodesLoading = false
  }),

  setNodeTypes: action((state, payload) => {
    state.nodeTypes = payload
  }),

  openNodeTypesLoader: action((state) => {
    state.nodeTypesLoading = true
  }),

  closeNodeTypesLoader: action((state) => {
    state.nodeTypesLoading = false
  }),

  openCreateNodeLoader: action((state) => {
    state.createNodeLoading = true
  }),

  closeCreateNodeLoader: action((state) => {
    state.createNodeLoading = false
  }),

  openRegisterVENtoVTNLoadingLoader: action((state) => {
    state.registerVENtoVTNLoading = true
  }),

  closeRegisterVENtoVTNLoadingLoader: action((state) => {
    state.registerVENtoVTNLoading = false
  }),

  setRefreshNodes: action((state, payload) => {
    state.refreshNodes = payload
  }),

  getNodes: thunk(async (actions, { typeId = [], withRegistrations = false }) => {
    let results = []
    actions.openNodesLoader()

    const filters = {
      typeId: typeId,
      withRegistrations: withRegistrations,
    }

    try {
      results = await getNodes(filters)
      actions.setNodes(results.data ?? [])
    } catch (e) {
      apiErrorHandler(e)
    }

    actions.closeNodesLoader()
  }),

  getNodeTypes: thunk(async (actions) => {
    let results = []
    actions.openNodeTypesLoader()

    try {
      results = await getNodeTypes()
      actions.setNodeTypes(results.data ?? [])
    } catch (e) {
      apiErrorHandler(e)
    }

    actions.closeNodeTypesLoader()
  }),

  createNode: thunk(async (actions, payload) => {
    actions.openCreateNodeLoader()

    await postNode(payload)
      .then(() => {
        toast.success(`Node has been saved.`)
      })
      .catch((err) => {
        apiErrorHandler(err)
      })

    actions.closeCreateNodeLoader()
  }),

  registerVENtoVTN: thunk(async (actions, params) => {
    const { id, ...payload } = params
    actions.openRegisterVENtoVTNLoadingLoader()

    await postRegisterVENtoVTN(id, payload)
      .then(() => {
        toast.success(`Node has been registered.`)
      })
      .catch((err) => {
        apiErrorHandler(err)
        throw err
      })
      .finally(() => {
        actions.closeRegisterVENtoVTNLoadingLoader()
      })
  }),
}

export default OpenADR
